import { CalendarEventStatus } from "fieldpro-tools/dist/src/types/calendarEvents";

import * as colors from "assets/colors";
import { IStyles } from "model/application/styles";

import { makeColorTransparent } from "./utils/makeColorTransparent";

export const EventColors = {
  statusText: {
    [CalendarEventStatus.COMPLETED]: colors.deliveredText,
    [CalendarEventStatus.OUTDATED]: colors.draftText,
    [CalendarEventStatus.PENDING]: colors.waitingText,
    [CalendarEventStatus.ONGOING]: colors.confirmedText,
    [CalendarEventStatus.PLANNED]: colors.confirmedText,
    [CalendarEventStatus.DECLINED]: colors.cancelledText,
    [CalendarEventStatus.CANCELLED]: colors.cancelledText,
    [CalendarEventStatus.DELETED]: colors.cancelledText,
    [CalendarEventStatus.ARCHIVED]: colors.cancelledText,
    default: colors.GreyDark,
  },
  fillColor: {
    [CalendarEventStatus.COMPLETED]: colors.delivered,
    [CalendarEventStatus.OUTDATED]: makeColorTransparent(colors.draft, 0.6),
    [CalendarEventStatus.PENDING]: colors.waiting,
    [CalendarEventStatus.ONGOING]: colors.confirmed,
    [CalendarEventStatus.PLANNED]: colors.confirmed,
    [CalendarEventStatus.DECLINED]: colors.cancelled,
    [CalendarEventStatus.CANCELLED]: colors.cancelled,
    [CalendarEventStatus.DELETED]: colors.cancelled,
    [CalendarEventStatus.ARCHIVED]: colors.cancelled,
    default: colors.GreyDark,
  },
};

export const styles: IStyles = {
  root: {
    display: "grid",
    gridRowGap: "16px",
  },
  header: {},
  title: {
    fontWeight: "600",
    fontSize: "24px",
    color: colors.Black,
    display: "flex",
    gridColumnGap: "8px",
  },
  icon: {
    paddingRight: "8px",
  },
  addVisitDialogWrapper: {
    background: colors.White,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    right: 0,
    zIndex: 6,
    overflowY: "scroll",
  },
};

export const boardItemStyles = {
  containerBase: {
    // backgroundColor: getEventFillColor("default"),
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15);",
    cursor: "pointer",
    transition: "0.3s",
    height: "100%",
    width: "92%",
    borderRadius: "4px",
    overflow: "hidden",
  },
  containerActive: {
    backgroundColor: colors.PaleYellowOrange + " !important",
  },
  containerHover: {
    "&:hover": {
      backgroundColor: colors.OffWhiteLight + " !important",
    },
  },
  containerHoverActive: {
    "&:hover": {
      backgroundColor: colors.PaleYellowOrange + " !important",
    },
  },
  containerLarge: {
    paddingLeft: "4px",
    padding: "8px",
  },

  padBase: {
    // borderLeft: `3.5px solid ${getEventStatusColor("default")}`,
    height: "100%",
    paddingLeft: "8px",
    display: "flex",
    flexDirection: "column",
    alignContent: "left",
    justifyItems: "center",
    columnGap: "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "left",
  },
  padActive: {
    borderLeft: `3.5px solid ${colors.Mustard} !important`,
  },
  padSmall: {
    paddingLeft: "2px",
    columnGap: "16px",
    alignItems: "center",
  },
  padVertical: {
    flexDirection: "row",
  },

  headingBase: {
    display: "grid",
    gridTemplateColumns: "auto minmax(auto,25px)",
    flexShrink: 0,
    flexGrow: 1,
    alignContent: "end",
  },
  headingSmall: {
    gridTemplateColumns: "auto",
  },

  timeSlotBase: {
    fontWeight: 500,
    color: colors.GreyDark,
    fontSize: "12px",
    textAlign: "left",
  },
  timeSlotHorizontal: {
    fontSize: "11px",
  },

  contentBase: {
    flexGrow: 2,
    alignItems: "start",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "16px",
    textWrap: "nowrap",
    paddingRight: "8px",
    maxWidth: "unset",
    alignContent: "center",
  },
  contentSmall: {
    flexGrow: 2,
    alignItems: "start",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "14px",
    textWrap: "nowrap",
    paddingRight: "8px",
    maxWidth: "95px",
  },
  contentHzSmall: {
    flexGrow: 2,
    alignItems: "start",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "12px",
    textWrap: undefined,
    paddingRight: "8px",
    maxWidth: "95px",
  },
  dayOnlyContentText: {
    fontSize: "16px",
    fontWeight: 600,
  },
} as const;

export const BOARD_ITEM_HEIGHT = 121;

export const stackedBoardStyles = {
  stackedBoardcontainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #F7F9FA",
  },
  boardSlot: {
    border: `1px solid #F7F9FA`,
    width: "100%",
  },
  slot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #F7F9FA",
    borderTop: "1px solid #F7F9FA",
    height: BOARD_ITEM_HEIGHT,
    borderRight: "1px solid #F7F9FA",
  },
  slotInner: {
    transition: "0.3s",
    height: "90%",
    width: "90%",
    cursor: "pointer",
    borderRadius: "4px",
  },
  boardItem: {
    height: BOARD_ITEM_HEIGHT,
    width: "100%",
    display: "flex",
    padding: "13px 8px",
    position: "relative",
  },
  boardItemInner: {
    position: "absolute",
    width: "100%",
    height: BOARD_ITEM_HEIGHT - 22,
    zIndex: 3,
  },
  placeholder: {
    height: BOARD_ITEM_HEIGHT,
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    padding: "13px 8px",
    opacity: 0.5,
  },
  fullHeight: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
  },
  icon: {
    fontSize: "16px",
  },
  label: {
    fontSize: "12px",
    color: colors.GreyDark,
  },
  customerName: {
    fontSize: "16px",
    fontWeight: 700,
  },
} as const;
