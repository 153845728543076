import { IDSource } from "__utils__/utils";
import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { CSV_ARRAY_SEPARATOR } from "containers/lists/subcategories/items/parseCsvFunctions";
import { IOption } from "model/application/components";
import { IParseCSVFunc } from "model/application/modal/BulkModal";
import { Gender } from "model/entities/User";
import { isEmailValid, isPhoneValid } from "utils/utils";

import { ICreateEditMobUserAttributes } from "../../model/ICreateEditMobUserAttributes";
import { ICreateEditWebUserAttributes } from "../../model/ICreateEditWebUserAttributes";

export const getErrorMessagesForMobileUser: TValidateFunction<
  ICreateEditMobUserAttributes
> = ({ attributes, additionnalProps, lang }) => {
  const { first_name = "", last_name = "", phone = "" } = attributes || {};
  const { phoneRegex } = additionnalProps;
  const result: any = {};
  if (first_name.length === 0)
    result["first_name"] = lang.components.inputErrors.empty;
  if (last_name.length === 0)
    result["last_name"] = lang.components.inputErrors.empty;
  if (phone.length === 0 || !isPhoneValid(attributes.phone, phoneRegex))
    result["phone"] = lang.components.inputErrors.empty;
  if (Object.keys(result).length === 0) return {};
  return result;
};

export const getErrorMessagesForWebUser: TValidateFunction<
  ICreateEditWebUserAttributes
> = ({ attributes, lang }) => {
  const { first_name = "", last_name = "", email = "", role = "" } = attributes;
  const result: any = {};
  if (first_name.length === 0)
    result["first_name"] = lang.components.inputErrors.empty;
  if (last_name.length === 0)
    result["last_name"] = lang.components.inputErrors.empty;
  if (role.length === 0) result["role"] = lang.components.inputErrors.empty;
  if (email.length === 0 || !isEmailValid(attributes.email))
    result["email"] = lang.components.inputErrors.empty;
  if (email.length > 0 && /[A-Z]/g.test(email))
    result["email"] = lang.components.inputErrors.emailContainUppercase;
  if (Object.keys(result).length === 0) return {};
  return result;
};

export const parseMobileUserCSV: IParseCSVFunc<ICreateEditMobUserAttributes> = (
  lines,
  csvColumnNames
) => {
  try {
    // output array
    const users: ICreateEditMobUserAttributes[] = [];

    // creates objects from csv data if column option included
    let l;
    let words;
    // loop through each line of csv file
    for (l = 1; l <= lines.length - 1; l++) {
      if (!lines[l]) {
        continue;
      }

      // splits each line of csv by comma
      words = lines[l];

      if (words.length !== csvColumnNames.length) {
        return {
          err: `The CSV should have exactly ${
            csvColumnNames.length
          } columns: ${csvColumnNames.join(", ")}`,
        };
      }

      if (words[1] === "") {
        return { err: "Cannot leave the first name blank" };
      }

      if (words[3] === "") {
        return { err: "Cannot leave the last name blank" };
      }

      if (words[5] === "") {
        return { err: "Cannot leave the phone number blank" };
      }

      let formattedPhone = words[5]
        .replace(/[{()}]/g, "")
        .replace(/\s/g, "")
        .replace(/-/g, "");

      if (formattedPhone[0] !== "+") formattedPhone = `+${formattedPhone}`;

      if (
        words[12] !== "" &&
        words[12] !== Gender.Male &&
        words[12] !== Gender.Female
      ) {
        return {
          err: `Invalid gender, need to be ${Gender.Male} or ${Gender.Female}`,
        };
      }

      if (words[14] !== "true" && words[14] !== "false") {
        return { err: "Column licensed must be set to 'true' or 'false'" };
      }

      let user_start_location: ICreateEditMobUserAttributes["user_start_location"];

      const lat = words[16] ? parseFloat(words[16]) : undefined;
      const lng = words[17] ? parseFloat(words[17]) : undefined;
      const accProvided = words[18] ? parseFloat(words[18]) : undefined;

      if (lat !== undefined && lng !== undefined) {
        const acc = accProvided ?? 3;
        user_start_location = {
          lat: lat,
          lng: lng,
          acc: acc,
        };
      } else {
        user_start_location = undefined;
      }

      const user: ICreateEditMobUserAttributes = {
        id: words[0] ?? IDSource(),
        first_name: words[1],
        middle_name: words[2],
        last_name: words[3],
        email: words[4],
        phone: formattedPhone,
        picture: words[6],
        birthday: words[7],
        start_date: words[8],
        internal_id: words[9],
        official_id: words[10],
        id_picture: words[11],
        gender: words[12] as Gender,
        role: words[13] || "",
        licensed: words[14] === "true",
        linked_teams: words[15]
          ? [
              ...new Set(
                words[15]
                  .split(CSV_ARRAY_SEPARATOR)
                  .map((w) => w.trim())
                  .filter(Boolean)
              ),
            ]
          : undefined,
        user_start_location,
      };

      const { id, ...userWithoutExtraInfo } = user;

      if (userWithoutExtraInfo.middle_name === "")
        delete (userWithoutExtraInfo as any).middle_name;

      if (userWithoutExtraInfo.email === "")
        delete (userWithoutExtraInfo as any).email;

      userWithoutExtraInfo["id"] = id;

      // pushes object to output array
      users.push(userWithoutExtraInfo as ICreateEditMobUserAttributes);
    }
    // returns output array
    return users;
  } catch (err: any) {
    return {
      err: err.message,
    };
  }
};

export const parseWebUserCSV: IParseCSVFunc<
  ICreateEditWebUserAttributes,
  { roleOptions: IOption[] }
> = (lines, csvColumnNames, additionnalProps) => {
  const isIDInColumns = csvColumnNames[0] === "ID";
  try {
    // output array
    const users: ICreateEditWebUserAttributes[] = [];

    // creates objects from csv data if column option included
    let l;
    // loop through each line of csv file
    for (l = 1; l <= lines.length - 1; l++) {
      if (!lines[l]) {
        continue;
      }

      // splits each line of csv by comma
      const words = lines[l];

      if (isIDInColumns && words[0] === "")
        return { err: "Cannot leave the first name blank" };
      const offset = isIDInColumns ? 1 : 0;
      if (words.length !== 7 + offset) {
        throw new Error(
          `The CSV should have only ${
            offset + 7
          } columns: first, middle, last name, email, role, dashboard role and linked teams`
        );
      }
      if (words[offset + 0] === "")
        throw new Error("Cannot leave the first name blank");
      if (words[offset + 2] === "")
        throw new Error("Cannot leave the last name blank");
      if (words[offset + 3] === "")
        throw new Error("Cannot leave the email blank");
      if (words[offset + 4] === "")
        throw new Error("Cannot leave the role blank");
      const user: Partial<ICreateEditWebUserAttributes> = {
        first_name: words[offset + 0],
        middle_name: words[offset + 1],
        last_name: words[offset + 2],
        email: words[offset + 3],
        role: words[offset + 4],
        id: isIDInColumns ? words[0] : IDSource(), // will be removed just after
        business_role: words[offset + 5], // TODO: to remove, no longer used, kept to not disturb existing bulk uploads
        linked_teams: words[offset + 6]
          ? [
              ...new Set(
                words[offset + 6]
                  .split("-")
                  .map((w) => w.trim())
                  .filter(Boolean)
              ),
            ]
          : undefined,
      };

      if (user.middle_name === "") delete user.middle_name;
      delete user.business_role;

      const { id, ...userWithoutExtraInfo } = user;
      // check the role
      const differentRolePossible = additionnalProps.roleOptions.map(
        (r) => r.key
      );
      if (!differentRolePossible.includes(words[offset + 4])) {
        throw new Error(
          `${
            words[offset + 4]
          } is not a recognized role. You can only set a role among those: ${differentRolePossible.join(
            ","
          )}`
        );
      }

      if (isIDInColumns) userWithoutExtraInfo["id"] = id;
      // pushes object to output array
      users.push(userWithoutExtraInfo as ICreateEditWebUserAttributes);
    }
    // returns output array
    return users;
  } catch (err: any) {
    return {
      err: err.message,
    };
  }
};
