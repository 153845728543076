import { useState } from "react";

import { DEFAULT_DATE_FILTER } from "model/entities/Client";

const getDateRange = (
  newDate: Date,
  dateType?: DEFAULT_DATE_FILTER
): {
  startDate: Date;
  endDate: Date;
} => {
  const date = newDate;

  const startDate = new Date(date.getTime());
  const endDate = new Date(date.getTime());

  let currentDay;

  switch (dateType) {
    case DEFAULT_DATE_FILTER.TODAY:
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.YESTERDAY:
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(-24);
      endDate.setHours(-24);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.LAST_7_DAYS:
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(-24 * 7);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.LAST_30_DAYS:
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(-24 * 30);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.LAST_WEEK:
      currentDay = date.getDay() || 7;
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(-24 * (currentDay + 6));
      endDate.setHours(-24 * currentDay);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.THIS_WEEK:
      currentDay = date.getDay() || 7;
      startDate.setHours(-24 * (currentDay - 1));
      startDate.setHours(0, 0, 0, 0);
      break;
    case DEFAULT_DATE_FILTER.LAST_MONTH:
      if (date.getMonth() === 0) {
        startDate.setFullYear(date.getFullYear() - 1);
      }
      startDate.setDate(1);
      startDate.setMonth((date.getMonth() || 12) - 1);
      startDate.setHours(0, 0, 0, 0);
      endDate.setDate(0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case DEFAULT_DATE_FILTER.THIS_MONTH:
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
      break;
    default:
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
  }
  return {
    startDate,
    endDate,
  };
};

const useChangeDate = (
  dateValue:
    | { dateType?: DEFAULT_DATE_FILTER; startDate?: Date; endDate?: Date }
    | undefined
) => {
  const initialValue = DEFAULT_DATE_FILTER.YESTERDAY;
  const [dateType, setDateType] = useState<DEFAULT_DATE_FILTER | undefined>(
    dateValue?.dateType || dateValue?.startDate
      ? dateValue.dateType
      : initialValue
  );
  const [startDate, setValueStartDate] = useState(
    dateValue?.startDate ?? getDateRange(new Date(), initialValue).startDate
  );
  const [endDate, setValueEndDate] = useState(
    dateValue?.endDate ?? getDateRange(new Date(), initialValue).endDate
  );

  const [refreshInputDate, setRefreshInputDate] = useState(new Date()); // [TODO] there should be a cleaner way to do it with hooks, but it works well for now
  const [refreshInputType, setRefreshInputType] = useState(new Date()); // [TODO] there should be a cleaner way to do it with hooks, but it works well for now

  const setSingleDay = (day: Date) => {
    setValueStartDate(getDateRange(new Date(day)).startDate);
    setValueEndDate(getDateRange(new Date(day)).endDate);
    setDateType(undefined);
    setRefreshInputType(new Date());
  };

  const setStartDate = (startDate: Date) => {
    setValueStartDate(getDateRange(startDate).startDate);
    setDateType(undefined);
    setRefreshInputType(new Date());
  };

  const setEndDate = (endDate: Date) => {
    setValueEndDate(getDateRange(endDate).endDate);
    setDateType(undefined);
    setRefreshInputType(new Date());
  };

  const setToggle = (value: DEFAULT_DATE_FILTER) => {
    setDateType(value);
    const startDate = getDateRange(new Date(), value).startDate;
    const endDate = getDateRange(new Date(), value).endDate;
    setValueStartDate(startDate);
    setValueEndDate(endDate);
    setRefreshInputDate(new Date());
  };

  return {
    dateType,
    setDateType: setToggle,
    startDate,
    endDate,
    setSingleDay,
    setStartDate,
    setEndDate,
    refreshInputDate,
    refreshInputType,
  };
};

const hooks = {
  useChangeDate,
  getDateRange,
};

export default hooks;
