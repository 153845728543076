import { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Tab, Tabs } from "components/Tab";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { allListsSelector } from "containers/lists/redux/selectors";
import { getPictureInformations as getPictureMetaData } from "containers/pictures/utils";
import { allUsersComposedSelector } from "containers/users/redux/selectors";
import {
  getAllActivities,
  getAllWorkflows,
} from "containers/workflows/redux/selectors";
import { useActions } from "hooks";
import useTranslations from "hooks/useTranslations";
import { IListItem } from "model/entities/ListItem";
import TPicture from "model/entities/Picture";

import { IImageDetectionDataFE } from "../types";
import PictureMetaData from "./PictureMetaData";
import ScanResultsTab from "./ScanResultsTab";

const useStyles = makeStyles(() => ({
  scrollBox: {
    overflowY: "auto",
  },
}));

export interface IPictureSideBarProps {
  picture: TPicture;
  activeBoxIds?: string[];
  detections: IImageDetectionDataFE[];
  toggleDisplayDetections?: (value: boolean) => void;
  isLoadingScanResults?: boolean;
  onClickScanResult?: (selectedBoxIds?: string[] | undefined) => void;
  onHoverScanResult?: (selectedBoxIds?: string[] | undefined) => void;
}

function PictureSideBar({
  picture,
  detections,
  activeBoxIds,
  toggleDisplayDetections,
  isLoadingScanResults,
  onClickScanResult,
  onHoverScanResult,
}: IPictureSideBarProps) {
  const classes = useStyles();

  const lang = useTranslations();
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;

  const client = useSelector(getSelectedClient);
  const showScanResultsTab = !_.isEmpty(client.detectable_lists);

  /**
   * PICTURE METADATA
   */
  const [isLoadingMetaData, setIsLoadingMetaData] = useState(true);
  const [linkedItem, setLinkedItem] = useState<IListItem | undefined>();

  const allActivities = useSelector(getAllActivities);
  const allLists = useSelector(allListsSelector);
  const allUsers = useSelector(allUsersComposedSelector);
  const allWorkflows = useSelector(getAllWorkflows);
  const fetchItemsForList = useActions(fetchItemsForListAction);

  const pictureListId = picture["list_id"];
  const pictureItemId = picture["item_id"];

  useEffect(() => {
    (async () => {
      setIsLoadingMetaData(true);
      let item;
      if (pictureListId && pictureItemId) {
        const result = await fetchItemsForList(pictureListId, {
          filters: { _id: pictureItemId },
        });
        const { items } = result || {};
        item = items?.[0];
        setLinkedItem(item);
      }
      setIsLoadingMetaData(false);
    })();
  }, [pictureListId, pictureItemId, fetchItemsForList]);

  const pictureMetaData = getPictureMetaData({
    picture,
    allActivities,
    allLists,
    allUsers,
    allWorkflows,
    client,
    item: linkedItem,
  });

  function enableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(true);
    }
  }
  function disableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(false);
    }
  }

  return (
    <Tabs animateTabSwitch>
      <Tab
        id={"details"}
        label={
          lang.containers.workflows.subCategories.activityReports.detailDialog
            .details
        }
      >
        <Box className={classes.scrollBox}>
          <PictureMetaData
            pictureMetaData={pictureMetaData}
            loading={isLoadingMetaData}
          />
        </Box>
      </Tab>

      <Tab
        id="scan-result"
        label={langKey.detectionsSideBar.options.scanResults}
        overflow="hidden"
        hidden={!showScanResultsTab || _.isEmpty(client.detectable_lists)}
      >
        <Box className={classes.scrollBox}>
          <ScanResultsTab
            picture={picture}
            activeBoxIds={activeBoxIds}
            isLoadingScanResults={isLoadingScanResults}
            detections={detections}
            onMount={enableDisplayDetections}
            onUnmount={disableDisplayDetections}
            onClickScanResult={onClickScanResult}
            onHoverScanResult={onHoverScanResult}
          />
        </Box>
      </Tab>
    </Tabs>
  );
}

export default PictureSideBar;
