import React from "react";

import { BoxProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

import { TCalendarEventFE } from "./redux/types";
import { IStackBoardProps } from "./StackedBoard";
import { boardItemStyles, EventColors } from "./styles";

export interface IStackedBoardItemProps {
  event: Partial<TCalendarEventFE>;
  onClickOnBoardItem?: IStackBoardProps["onClickOnBoardItem"];
  boxProps?: BoxProps;
  active?: boolean;
  classes?: ClassNameMap<keyof typeof boardItemStyles>;
  headingText?: string;
}
const StackedBoardItem: React.FC<Readonly<IStackedBoardItemProps>> = React.memo(
  ({ event, onClickOnBoardItem, active, classes, headingText }) => {
    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
      onClickOnBoardItem && onClickOnBoardItem(e, event as TCalendarEventFE);
    }

    return (
      <div
        style={{
          backgroundColor: EventColors.fillColor[event.status ?? "default"],
        }}
        data-status={event.status}
        className={classNames(
          classes?.containerBase,
          active ? classes?.containerHoverActive : classes?.containerHover,
          active && classes?.containerActive
        )}
        onClick={handleClick}
      >
        <div
          className={classNames(classes?.padBase, active && classes?.padActive)}
          style={{
            borderLeft: `3.5px solid ${
              EventColors.statusText[event.status ?? "default"]
            }`,
          }}
        >
          <div className={classes?.headingBase} data-testid="heading">
            <div className={classes?.timeSlotBase}>{headingText}</div>
          </div>
          <div
            className={classNames(
              classes?.contentBase,
              classes?.dayOnlyContentText
            )}
          >
            {event.title}
          </div>
        </div>
      </div>
    );
  }
);

export default StackedBoardItem;
