import { getAuthorizedRoles, IAccessRightProfile } from "fieldpro-tools";
import _ from "lodash";
import { createSelector } from "reselect";

import { getHasAccessToAllResources } from "containers/calendar/redux/selectors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import {
  allMobileUsersSelector,
  getMobileUserTeammates,
} from "containers/users/redux/selectors/mobileUsersSelector";
import IRootState from "redux/store/model";

/**
 * Gets the state of signing up
 * @param {Object} state Redux Store state
 */
export const getIsSigningup = (state: IRootState) => state.auth.SigningUp;

/**
 * Gets the state of loggin out
 * @param {Object} state Redux Store state
 */
export const getIsLoggingOut = (state: IRootState) => state.auth.loggingOut;

export const getShouldChangePassword = (state: IRootState) =>
  state.auth.shouldChangePassword;
export const getIsChangingPassword = (state: IRootState) =>
  state.auth.isChangingPassword;

/**
 * Gets the currently logged in user
 * @param {Object} state Redux store state
 * @return {Object}
 */
export const getSignedInUser = (state: IRootState) => state?.auth?.user;

/**
 * Gets the current language
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getLang = (state: IRootState) =>
  state?.clients?.selectedClient?.language || state?.auth?.lang || "en";

/**
 * Gets the date of the last check version endpoint
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getLastCheckVersion = (state: IRootState) =>
  state.auth.lastCallToCheckVersion;

export const getIsUsingLastVersion = (state: IRootState) =>
  state.auth.isUsingLastVersion;
/**
 *  @return roles that the current user is allowed to create:
 *  - roles that are "less important" than the logged user, i.e with less permissions
 *  - the role the user currently has (only if they don't have an Optimetriks role)
 */
export const getAuthorizedRolesSelector = (state: IRootState) => {
  if (!state.clients.selectedClient) {
    return [];
  }

  const userRole = state.clients.selectedClient.profile;
  if (!userRole) {
    return [];
  }

  const profiles = state.clients.selectedClient.web_access_right_profiles ?? [];

  return getAuthorizedRoles(profiles ?? [], userRole);
};

export const getRolesWithRestrictedAccess = (state: IRootState) => {
  return (state.clients.selectedClient.web_access_right_profiles ?? [])
    .filter((p) => (p.access_all_resources ? false : true))
    .map((p) => p.name);
};

/**
 * Gets the role of the user
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getPrivilege = (state: IRootState) => {
  const role = state.clients.selectedClient.profile;
  return role;
};

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsPasswordRecovering = (state: IRootState) =>
  state.auth.isPasswordRecovering;

/**
 * Gets value of retryWait
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getRetryWait = (state: IRootState) => state.auth.retryWait;
export const getAttemptsCount = (state: IRootState) => state.auth.attempts;

/**
 * Selector to get the token from the Store
 * @param {Object} state State of store
 */
const getToken = (state: IRootState) => {
  /*if (state.ajax.mustLogOut) {
		return null;
	}*/
  return state.auth?.smalaToken;
};

/**
 * Get the logging in state from Redux Store
 * @param {Object} state Redux store state
 * @returns {Boolean} returns the state of logging in
 */

/**
 * Selector to get the Authentication status of the User
 * @param {Object} state State of store
 */
const getAuthStatus = (state: IRootState) => state.auth.user.isAuthenticated;

/**
 * Reselect function that composes the selector function to get the token to use for authentication
 */
export const tokenSelector = createSelector([getToken], (token) => token);

/** Reselect to compose selector to retrieve the authentication status*/
export const authStatusSelector = createSelector(
  [getAuthStatus],
  (authenticated) => authenticated
);

/** Selector to get the state of the application on logging in*/

/**
 * Get logg
 * @param {Object} state Redux store state
 * @returns {Array} Array of errors encountered on authentication
 */
const getAuthErrors = (state: IRootState) => state.auth.errors;

/** Get Auth errors selector */
export const authErrorsSelector = createSelector(
  getAuthErrors,
  (authErrors) => authErrors
);

/**
 * Get has login error
 * @param {Object} state Redux Store state
 * @returns {Boolean} True if there is a login error
 */
const getHasLoginError = (state: IRootState) => state.auth.loginError;

/** selector for the login errror */
export const hasLoginErrorSelector = createSelector(
  getHasLoginError,
  (hasLoginError) => hasLoginError
);

/**
 * Get login error message
 * @param {Object} state Redux Store state
 * @returns {String} True if there is a login error
 */
const getErrorMessage = (state: IRootState) => state.auth.errorMessage;

/** selector for the login errror */
export const errorMessageSelector = createSelector(
  getErrorMessage,
  (errorMessage) => errorMessage
);

const getActionsAvailable = (state: IRootState) => state.auth.availableActions;

export const getActionsAvailableSelector = createSelector(
  getActionsAvailable,
  (actionsAvailable) => actionsAvailable
);

export const getAccessRightProfile = (state: IRootState): IAccessRightProfile =>
  state.auth.accessRightProfile;

export const getAccessRightProfileSelector = createSelector(
  getAccessRightProfile,
  (accessRightProfile) => accessRightProfile
);

// https://stackoverflow.com/a/64887804/6627882
export const hasPermissionSelector = createSelector(
  [getActionsAvailable, (_state, actionCode) => actionCode],
  (actionsAvailable, actionCode) => actionsAvailable[actionCode]
);

export const isLinkedToMobileUser = createSelector(
  [getSelectedClient, getSignedInUser, allMobileUsersSelector],
  (_client, signedUser, mobileUsers) => {
    const linkedMobileUser = _.find(mobileUsers, { email: signedUser.email });
    return !!linkedMobileUser;
  }
);

/**
 * Getter for wether the user is a manager (linked mob user is a team_leader)
 */
export const getLinkedMobileUser = createSelector(
  [getSignedInUser, allMobileUsersSelector],
  (signedUser, mobileUsers) => {
    const linkedMobileUser = _.find(mobileUsers, { email: signedUser.email });
    return linkedMobileUser;
  }
);
export const isLinkedToMobileUserWithNoFullAccess = createSelector(
  [getSelectedClient, isLinkedToMobileUser],
  (client, isLinkedToMobileUser) => {
    const userProfile = _.find(client?.web_access_right_profiles ?? [], {
      name: client?.profile,
    });
    return !userProfile?.access_all_resources && isLinkedToMobileUser;
  }
);
export const getScreenMessages = (state: IRootState) =>
  state.auth.screenMessages;

// this is moved here instead of in mobileUsersSelector.ts to avoid a circular dependency error
export const mobileUserTeamMatesComposedSelector = createSelector(
  [
    (state) => getHasAccessToAllResources(state),
    allMobileUsersSelector,
    getMobileUserTeammates,
  ],
  (hasAccessToAllResources, allMobileUsers, mobileUserTeamMates) => {
    const fullMobileUserTeamMates = _.map(mobileUserTeamMates, (tm) => {
      const mobileUser = _.find(allMobileUsers, { id: tm.id });
      return {
        ...(mobileUser || {}),
        ...tm,
      };
    });
    return hasAccessToAllResources ? allMobileUsers : fullMobileUserTeamMates;
  }
);
