import { IDashboard as IBEDashboard } from "fieldpro-tools/dist/src/types/dashboards";
import _ from "lodash";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { fetchTeamsForClientAction } from "containers/teams/redux/actions";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IDashboard, IKPI } from "model/entities/Dashboard";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import { PartialFailureBusinessError } from "../../../redux/actions/actionErrors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../redux/actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as levels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import { prepareDashboardsForFrontEnd } from "../components/GeoTrackingChart/utils/prepareDashboardsForFrontEnd";
import {
  archiveDashboardsBeginActionCreator,
  archiveDashboardsFailureActionCreator,
  archiveDashboardsSuccessActionCreator,
  computeDashboardBeginActionCreator,
  computeDashboardFailureActionCreator,
  computeDashboardSuccessActionCreator,
  computeKpiBeginActionCreator,
  computeKpiFailureActionCreator,
  computeKpiSuccessActionCreator,
  createDashboardBeginActionCreator,
  createDashboardFailureActionCreator,
  createDashboardSuccessActionCreator,
  deleteDashboardBeginActionCreator,
  deleteDashboardFailureActionCreator,
  deleteDashboardSuccessActionCreator,
  downloadDashboardKpiBeginAction,
  downloadDashboardKpiFailureAction,
  downloadDashboardKpiSuccessAction,
  fetchAllDashboardsBeginActionCreator,
  fetchAllDashboardsFailureActionCreator,
  fetchAllDashboardsSuccessActionCreator,
  fetchFrequentlyViewedDashboardsBeginActionCreator,
  fetchFrequentlyViewedDashboardsFailureActionCreator,
  fetchFrequentlyViewedDashboardsSuccessActionCreator,
  restoreDashboardsBeginActionCreator,
  restoreDashboardsFailureActionCreator,
  restoreDashboardsSuccessActionCreator,
  updateDashboardBeginActionCreator,
  updateDashboardFailureActionCreator,
  updateDashboardSuccessActionCreator,
} from "./actionCreators";
import {
  archiveDashboardsApiCall,
  computeDashboardsApiCall,
  computeKpiApiCall,
  createDashboardApiCall,
  deleteDashboardApiCall,
  downloadKpiApiCall,
  fetchDashboardsApiCall,
  fetchFrequentlyViewedDashboardsApiCall,
  restoreDashboardsApiCall,
  updateDashboardApiCall,
} from "./api";
import { generateErrorBlocks, prepareDashboardsForFrontend } from "./utils";

/**
 * Create Dashboard action which creates a dashboard for embedding
 * @param {String} title Title of the dashboard
 * @param {String} type Type of the dashboard (between WEB, MOBILE, WEB_AND_MOBILE)
 * @param {String} description Description of the dashboard
 * @param {Array} charts Charts of dashboards
 * @returns {Function}
 */
export const createDashboardAction: ICreateDashboardActionFunc = (
  actionName: string,
  dashboard: IDashboard
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createDashboardBeginActionCreator());

    return createDashboardApiCall(actionName, dashboard)
      .then((serverResponse) => {
        const dashboardCreated = extractDataAndCheckErrorStatus(serverResponse);

        dispatch(ajaxSuccessAction());
        dispatch(createDashboardSuccessActionCreator(dashboardCreated));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateDashboard",
          error,
          createDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update Dashboard action which creates a dashboard for embedding
 * @param {Object} dashboard Dashboard object to edit
 * @returns {Function}
 */
export const updateDashboardAction: IUpdateDashboardActionFunc = (
  actionName: string,
  dashboard
) => {
  dashboard.kpis.forEach((kpi) => delete kpi.data);
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateDashboardBeginActionCreator());

    return updateDashboardApiCall(actionName, dashboard)
      .then((serverResponse) => {
        const dashboardUpdated = extractDataAndCheckErrorStatus(serverResponse);

        const prepareDashboardForFE = prepareDashboardsForFrontend([
          dashboardUpdated as IBEDashboard,
        ])[0];

        dispatch(ajaxSuccessAction());
        dispatch(updateDashboardSuccessActionCreator(prepareDashboardForFE));

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.DASHBOARD,
              dashboard.title
            )
          )
        );
      })
      .then(() => dispatch(fetchTeamsForClientAction() as any))
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateDashboard",
          error,
          updateDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch allDashboards
 * @returns {Function}
 */
export const fetchAllDashboardsAction: IFetchAllDashboardsActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(fetchAllDashboardsBeginActionCreator());

    return fetchDashboardsApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { dashboards } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          fetchAllDashboardsSuccessActionCreator(
            prepareDashboardsForFrontEnd(dashboards)
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchAllDashboardsError",
          error,
          fetchAllDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete Dashboard Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {String} dashboard_id Dashboard id to delete
 * @param {String} dashboard_name Dashboard name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteDashboardAction: IDeleteDashboardActionFunc = (
  actionName: string,
  dashboardId,
  dashboardName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteDashboardBeginActionCreator());

    return deleteDashboardApiCall(actionName, dashboardId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteDashboardSuccessActionCreator(dashboardId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.DASHBOARD,
              dashboardName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteDashboardError",
          error,
          deleteDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Archive Dashboards Action dispatches action creators to redux store and makes api calls to archive dashboards
 * @param {String} dashboardIds Dashboard ids to archive
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const archiveDashboardsAction: IArchiveDashboardsActionFunc = (
  actionName: string,
  dashboardIds
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveDashboardsBeginActionCreator());

    return archiveDashboardsApiCall(actionName, dashboardIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveDashboardsSuccessActionCreator(dashboardIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveDashboardsError",
          error,
          archiveDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Restore Dashboards Action dispatches action creators to redux store and makes api calls to restore dashboards
 * @param {String} dashboardIds Dashboard ids to restore
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const restoreDashboardsAction: IRestoreDashboardsActionFunc = (
  actionName: string,
  dashboardIds
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreDashboardsBeginActionCreator());

    return restoreDashboardsApiCall(actionName, dashboardIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreDashboardsSuccessActionCreator(dashboardIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreDashboardsError",
          error,
          restoreDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

export const computeDashboardAction: IComputeDashboardActionFunc = (
  actionName: string,
  selectedDashboard,
  query,
  nbTotalOfTeams,
  callback,
  degradedModeKpis
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    if (degradedModeKpis) {
      dispatch(ajaxRequestAction());
      return computeDashboardsApiCall(
        actionName,
        selectedDashboard.id,
        query,
        degradedModeKpis
      )
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ComputeDashboardError",
            error,
            computeDashboardFailureActionCreator,
            currLang
          );
        });
    } else {
      dispatch(ajaxRequestAction());
      dispatch(computeDashboardBeginActionCreator());
      return computeDashboardsApiCall(
        actionName,
        selectedDashboard.id,
        query,
        degradedModeKpis
      )
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          let { kpis } = data;
          const { raw_data } = data;
          if (selectedDashboard.id === "_log_report") {
            kpis = kpis.map((k) => ({
              ...k,
              data: (k.data as any[]).map((d) => {
                delete d.id;
                return d;
              }),
            }));
          }
          dispatch(ajaxSuccessAction());
          if (
            query[FILTER_TAG.TEAMS] &&
            query[FILTER_TAG.TEAMS].length === nbTotalOfTeams
          ) {
            delete query[FILTER_TAG.TEAMS];
          }
          dispatch(
            computeDashboardSuccessActionCreator(
              kpis.filter(
                (k) =>
                  !(
                    k.data &&
                    (k.data as { error: any }).error &&
                    (k.data as { error: any }).error !== "timeout" &&
                    (k.data as { error: any }).error !== "query_disabled"
                  )
              ),
              selectedDashboard.id,
              query,
              raw_data
            )
          );

          if (callback) callback();

          const errorsKpis = _.filter(
            kpis,
            (kpi: any) =>
              kpi.data.error &&
              kpi.data.error !== "timeout" &&
              kpi.data.error !== "query_disabled"
          );
          const formattedKpis = _.compact(
            _.map(errorsKpis, (kpi: any) => {
              const dashKpi = selectedDashboard.kpis.find(
                (k) => kpi.tag === k.tag
              );
              if (!dashKpi) {
                return undefined;
              }
              return {
                kpiId: dashKpi ? dashKpi.tag : "",
                kpiTitle: dashKpi ? dashKpi.title : "",
                error: kpi.data.error as string,
              };
            })
          );

          if (formattedKpis.length > 0)
            throw new PartialFailureBusinessError(
              `All dashboards could not be loaded:\n\n${generateErrorBlocks(
                formattedKpis
              )}`
            );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ComputeDashboardError",
            error,
            computeDashboardFailureActionCreator,
            currLang
          );
        });
    }
  };
};

export const computeKpiAction: TComputeKpiActionFunc = (
  actionName: string,
  kpi: IKPI,
  dashboardId: string | undefined,
  query
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(computeKpiBeginActionCreator());
    return computeKpiApiCall(actionName, kpi, dashboardId, query)
      .then((serverResponse) => {
        const result = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(computeKpiSuccessActionCreator(kpi, query, result.data));
        if (
          result.data &&
          (result.data as { error: any }).error &&
          (result.data as { error: any }).error !== "timeout"
        ) {
          throw new PartialFailureBusinessError(
            "Error detected while running the query",
            (result.data as { error: any }).error
          );
        } else {
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              "Query executed successfully"
            )
          );
        }
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ComputeKpiError",
          error,
          computeKpiFailureActionCreator,
          currLang,
          false,
          kpi.tag
        );
      });
  };
};
/**
 * Fetch frequently viewed dashboards (returns an array of ids)
 * @returns {Function}
 */
export const fetchFrequentlyViewedDashboards: TFetchFrequentlyViewedDashboardsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchFrequentlyViewedDashboardsBeginActionCreator());

      return fetchFrequentlyViewedDashboardsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { dashboardIds } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchFrequentlyViewedDashboardsSuccessActionCreator(dashboardIds)
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchFrequentlyViewedDashboardsError",
            error,
            fetchFrequentlyViewedDashboardsFailureActionCreator,
            currLang
          );
        });
    };
  };

export const downloadKpiAction: TDownloadKpiActionFunc = (
  dashboardId,
  kpiId,
  actionName,
  query?: any
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(downloadDashboardKpiBeginAction());

    return downloadKpiApiCall({ dashboardId, kpiId, query, actionName })
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(downloadDashboardKpiSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.notifications.successNotifications.downloadChart
          )
        );
      })
      .catch((error: any) => {
        treatErrorNotification(
          dispatch,
          "DashboardKpiError",
          error,
          downloadDashboardKpiFailureAction,
          currLang
        );
      });
  };
};

export type ICreateDashboardActionFunc = (
  actionName: string,
  dashboard: IDashboard
) => IDispatchAndGetState<void>;
export type IUpdateDashboardActionFunc = (
  actionName: string,
  dashboard: IDashboard
) => IDispatchAndGetState<any>;
export type IFetchAllDashboardsActionFunc = () => IDispatchAndGetState<void>;
export type IDeleteDashboardActionFunc = (
  actionName: string,
  dashboardId: string,
  dashboardName: string
) => IDispatchAndGetState<void>;
export type IArchiveDashboardsActionFunc = (
  actionName: string,
  dashboardIds: string[]
) => IDispatchAndGetState<void>;
export type IRestoreDashboardsActionFunc = (
  actionName: string,
  dashboardIds: string[]
) => IDispatchAndGetState<void>;
export type IComputeDashboardActionFunc = (
  actionName: string,
  selectedDashboard: IDashboard,
  query: any,
  nbTotalOfTeams: number,
  callback?: any,
  degradedModeKpis?: string[]
) => IDispatchAndGetState<void>; // FIXME: change the "any" type to a more specific one
export type TFetchFrequentlyViewedDashboardsActionFunc =
  () => IDispatchAndGetState<void>;
export type IChangeSubcategoryActionFunc = (
  subcategory: string
) => IDispatchAndGetState<void>;
export type TComputeKpiActionFunc = (
  actionName: string,
  kpi: IKPI,
  dashboardId: string | undefined,
  query: any
) => IDispatchAndGetState<void>;

export type TDownloadKpiActionFunc = (
  dashboardId: string,
  kpiId: string,
  actionName: string,
  query?: any
) => IDispatchAndGetState<void>;

export interface IDashboardActions {
  createDashboardAction: ICreateDashboardActionFunc;
  updateDashboardAction: IUpdateDashboardActionFunc;
  fetchAllDashboardsAction: IFetchAllDashboardsActionFunc;
  deleteDashboardAction: IDeleteDashboardActionFunc;
  archiveDashboardsAction: IArchiveDashboardsActionFunc;
  restoreDashboardsAction: IRestoreDashboardsActionFunc;
  computeDashboardAction: IComputeDashboardActionFunc;
  computeKpiAction: TComputeKpiActionFunc;
  downloadKpiAction: TDownloadKpiActionFunc;
}

const actions: IDashboardActions = {
  createDashboardAction,
  updateDashboardAction,
  fetchAllDashboardsAction,
  deleteDashboardAction,
  archiveDashboardsAction,
  restoreDashboardsAction,
  computeDashboardAction,
  computeKpiAction,
  downloadKpiAction,
};

export default actions;
