import React, { ReactNode, useState } from "react";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import ColumnSelector from "components/ColumnSelector";
import InputSearch from "components/Input/InputSearch";
import CustomTable from "components/Table/CustomTable";
import {
  ILoadPage,
  ITableSorting,
} from "components/Table/CustomTable/CustomTable";
import { getRowId } from "components/Table/CustomTable/CustomTableRow.utils";
import { TColumnType } from "components/Table/model";
import TableMenu from "components/Table/TableMenu/TableMenu";
import useTranslations from "hooks/useTranslations";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";

import styles from "./styles";

const useStyles = makeStyles(styles as any);

export const ACTION_OUTSIDE_THE_ACTION_BAR = [
  ACTION_TYPE.ARCHIVE,
  ACTION_TYPE.DELETE,
  ACTION_TYPE.EDIT,
  ACTION_TYPE.RESTORE,
  ACTION_TYPE.DETAIL,
  ACTION_TYPE.RESTORE_VERSION,
  ACTION_TYPE.EXTRA_ACTION,
  ACTION_TYPE.STOP,
  ACTION_TYPE.RESUME,
  ACTION_TYPE.CONTINUE,
  ACTION_TYPE.REPLICATE,
];
export const BULK_ACTIONS = [
  ACTION_TYPE.CREATE_MULTIPLE,
  ACTION_TYPE.DELETE_MULTIPLE,
  ACTION_TYPE.ARCHIVE_MULTIPLE,
  ACTION_TYPE.RESTORE_MULTIPLE,
  ACTION_TYPE.EDIT_MULTIPLE,
];

export interface ICustomCardTableProps {
  isLoading?: boolean;
  columnTypes: TColumnType[];
  handleClearFilters?: () => void;
  onChangeColumnTypes?: (
    showColumns: TColumnType[],
    hidesColumns: TColumnType[]
  ) => void;
  data: any[];
  actions?: ITableAction[];
  onDetailItem?: any;
  onClickItemRow?: any;
  onSwitchChanged?: any;
  isFilter?: boolean;
  showSearch?: boolean;
  isTableChart?: boolean;
  searchLabel?: string;
  filterParams?: any;
  resetSelection?: boolean;
  attrToHide?: string[];
  noCardAround?: boolean;
  bigSize?: boolean;
  showSyntheticRow?: boolean;
  dbTotalRows?: number;
  clickableRows?: boolean;
  filterDropdownManager?: ReactNode;
  showColumnMenu?: boolean;
  columns?: any;
  dataCount?: number /** total count (with applied filters) */;
  totalCount?: number /** total count (without filters) */;
  defaultRowsPerPage?: number;

  defaultSorting?: ITableSorting;
  paginationDescription?: {
    description: string;
    tooltip?: string;
  };
  onDownloadCSV?: () => void;
  onDownloadExcel?: () => void;
  onChangeSorting?: (sorting: ITableSorting) => void;
  loadNextPage?: (page: ILoadPage) => void;
  handleSortRowsBackend?: (sorting: ITableSorting) => any;
  handleChangeRowsPerPage?: (pageSize: number) => void;
  onSearchBackend?: (searchTerm: string) => void;
}

const CustomCardTable: React.FC<ICustomCardTableProps> = ({
  isLoading,
  columnTypes,
  handleClearFilters,
  onChangeColumnTypes,
  data,
  actions = [],
  onDetailItem,
  onClickItemRow,
  onSwitchChanged,
  showSearch = true,
  isTableChart,
  searchLabel: _searchLabel,
  filterParams = {},
  resetSelection,
  attrToHide = ["_id", "_active", "_is_loading", "_block_actions"],
  noCardAround,
  bigSize,
  showSyntheticRow,
  clickableRows,
  filterDropdownManager,
  showColumnMenu,
  dataCount = data.length,
  totalCount = dataCount,
  defaultRowsPerPage,
  defaultSorting,
  dbTotalRows,
  onChangeSorting,
  loadNextPage,
  handleSortRowsBackend,
  handleChangeRowsPerPage,
  onSearchBackend,
  paginationDescription,
  onDownloadCSV,
  onDownloadExcel,
}) => {
  const lang = useTranslations();
  const searchLabel = _searchLabel ?? lang.components.search;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  React.useEffect(() => {
    if (!_.isUndefined(onSearchBackend) && !_.isUndefined(searchTerm)) {
      onSearchBackend(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearchChange = (termSearched: string): void => {
    if (termSearched != null) {
      setSearchTerm(termSearched);
    }
  };
  const debouncedHandleSearchChange = _.debounce(handleSearchChange, 500);

  const handleBulkAction = (bulkAction: any): void => {
    bulkAction(selectedItems);
  };

  const handleCheckChange = (items: string[]) => {
    const selectedItems: any[] = [];
    items.forEach((itemId) => {
      const fullItem = data.filter((it) => {
        if (it.id == null) {
          if (it._id === itemId) {
            return true;
          }
        } else if (it.id === itemId) {
          return true;
        }
        return false;
      })[0];
      if (fullItem) selectedItems.push(fullItem);
    });
    setSelectedItems(selectedItems);
  };

  // replace all the actions without disabled function to a "return false" function
  const actionsCorrected = (actions || ([] as any[])).map((action) => {
    if (action.disabled == null) {
      action.disabled = () => false;
    }
    return action;
  });
  // extract the actions which cannot go to the action bar
  const actionsInActionBar = actionsCorrected.filter(
    (action) => !ACTION_OUTSIDE_THE_ACTION_BAR.includes(action.actionType)
  );
  const bulkActions = actionsCorrected.filter((action) =>
    BULK_ACTIONS.includes(action.actionType)
  );
  const actionsInTable = actionsCorrected.filter((action) =>
    ACTION_OUTSIDE_THE_ACTION_BAR.includes(action.actionType)
  );
  const editAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.EDIT
  );
  const deleteAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.DELETE
  );
  const archiveAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.ARCHIVE
  );
  const restoreAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESTORE
  );
  const restoreVersionAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESTORE_VERSION
  );
  const viewAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.DETAIL
  );
  const stopAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.STOP
  );
  const resumeAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.RESUME
  );
  const continueAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.CONTINUE
  );
  const replicateAction = actionsInTable.find(
    (action) => action.actionType === ACTION_TYPE.REPLICATE
  );
  const extraActions = actionsInTable.filter(
    (action) => action.actionType === ACTION_TYPE.EXTRA_ACTION
  );
  const licenseAction = actionsInActionBar.find(
    (action) => action.actionType === ACTION_TYPE.LICENSE
  );
  const unlicenseAction = actionsInActionBar.find(
    (action) => action.actionType === ACTION_TYPE.UNLICENSE
  );

  return (
    <div className={classes.root}>
      <div
        className={
          noCardAround ? classes.customTableForChart : classes.customTable
        }
        style={
          // 48: tab banner selection
          // 7: action button (taking in account the negative bottom margin)
          {
            ...(noCardAround && { height: bigSize ? "800" : "400" }),
          }
        }
      >
        <CustomTable
          // NOTE: hack to refresh state -> prefer componentDidUpdate or similar methods inside class
          // key={JSON.stringify(data)}
          totalCount={totalCount}
          dataCount={dataCount}
          dbTotalRows={dbTotalRows}
          searchTerm={searchTerm}
          handleClearFilters={handleClearFilters}
          rowData={data}
          isLoading={isLoading}
          isTableChart={isTableChart}
          noCardAround={noCardAround}
          columnTypes={_.filter(columnTypes, (col) => !col.hidden)}
          hasCheckBox={actions && actions.length > 0}
          onCheckChanged={handleCheckChange}
          onSwitchChanged={onSwitchChanged}
          onDetailItem={onDetailItem}
          onClickItemRow={onClickItemRow}
          onEditItem={editAction ? editAction.action : undefined}
          onArchiveItem={archiveAction ? archiveAction.action : undefined}
          onRestoreItem={restoreAction ? restoreAction.action : undefined}
          onLicenseItem={licenseAction ? licenseAction.action : undefined}
          onUnLicenseItem={unlicenseAction ? unlicenseAction.action : undefined}
          onRestoreVersion={
            restoreVersionAction ? restoreVersionAction.action : undefined
          }
          onViewItem={viewAction ? viewAction.action : undefined}
          onDeleteItem={deleteAction ? deleteAction.action : undefined}
          onStopExecution={stopAction ? stopAction.action : undefined}
          onResumeExecution={resumeAction ? resumeAction.action : undefined}
          onContinueExecution={
            continueAction ? continueAction.action : undefined
          }
          onReplicateItem={replicateAction ? replicateAction.action : undefined}
          extraActions={extraActions}
          bulkActions={bulkActions}
          checkedRows={
            resetSelection ? [] : selectedItems.map((i) => getRowId(i))
          }
          onDownloadCSV={onDownloadCSV}
          onDownloadExcel={onDownloadExcel}
          paginationDescription={paginationDescription}
          attrToHide={isTableChart ? [] : attrToHide}
          showSyntheticRow={showSyntheticRow}
          clickableRows={clickableRows}
          filterParams={filterParams}
          searchBar={
            showSearch ? (
              <Box marginRight="16px">
                <InputSearch
                  placeholder={searchLabel}
                  onChange={debouncedHandleSearchChange}
                />
              </Box>
            ) : null
          }
          backendSearch={!!onSearchBackend}
          showColumnMenu={showColumnMenu}
          filterDropdownManager={filterDropdownManager}
          columnMenu={
            <ColumnSelector
              columnTypes={columnTypes}
              onChangeColumnTypes={onChangeColumnTypes}
            />
          }
          tableMenu={
            <TableMenu
              actions={actionsInActionBar}
              onBulkAction={handleBulkAction}
              selectedItems={selectedItems}
            />
          }
          defaultRowsPerPage={defaultRowsPerPage}
          defaultSorting={defaultSorting}
          onChangeSorting={onChangeSorting}
          loadNextPage={loadNextPage}
          handleSortRowsBackend={handleSortRowsBackend}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default CustomCardTable;
