import { IItemInTable, IListItem } from "model/entities/ListItem";

export function getItemLabel(item?: IListItem) {
  return item?._displayed_name || item?._name || "";
}

/**
 * @deprecated - DO NOT USE
 *
 * Rationale: _name and _displayed_name are different.
 * However, it is ok to fallback from _displayed_name to _name
 * if you are trying to display a label no matter what -> see getItemLabel
 */
export const getItemName = (item?: IListItem | IItemInTable) => {
  return item?._name || item?._displayed_name || "";
};
