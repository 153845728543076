import { IClient } from "model/entities/Client";

import { IClientsStates } from "./reducer";

export const EMPTY_INIT_CLIENT: IClient = {
  id: "",
  profile: "",
  private_key: "",
  dataset_name: "",
  client_email: "",
  name: "",
  description: "",
  dbname: "",
  downloads_quota_period: "",
  logo: "",
  gps_tracking_days: [],
  meta_hierarchy_dependencies: {},
  hierarchy_dependencies: [],
  web_access_right_profiles: [],
  mobile_access_right_profiles: [],
  access_right_profiles: [], // legacy
  go_back_days_filter: 0,
  app_user_licenses: 0,
  max_downloads: 0,
  downloads_count: 0,
  active: false,
  capacity_quota: 0,
  capacity_used: 0,
  main_dashboards: [],
  mobile_login_modes: [],
};

const initialState: IClientsStates = {
  allClients: [],
  allIds: [],
  byId: {},
  errors: [],
  // TODO: replace with null initialization (?) To avoid bugs like:
  // > if (!selectedClient) { // client is actually missing ! }
  selectedClient: EMPTY_INIT_CLIENT,
  isFetchingClient: false,
  isFetchingAllClients: false,
  isUpdatingClient: false,
  isDeletingClient: false,
  isCreatingClient: false,
  isArchivingClient: false,
  isRestoringClient: false,
  isReplicatingClient: false,
  lastUpdated: undefined,
  invalidateCache: false,
  owners: [],
  invoices: [],
};

export default initialState;
