import { useEffect, useState } from "react";

import { Box, Button, Link, Typography } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import FilterIcon from "@mui/icons-material/Filter";
import { DASHBOARD_TYPE, LIST_SCOPE, LIST_TYPE } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import CustomChips, {
  ICustomChipData,
} from "components/Chip/CustomChips/CustomChips";
import {
  BasicArrowIcon,
  BasicCloseIcon,
  BasicDialog,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
  BasicEditIcon,
} from "components/Dialog/BasicDialog";
import BasicScannerIcon from "components/Dialog/BasicDialog/Icons/BasicScannerIcon";
import DialogPlaceHolder from "components/Dialog/DialogPlaceHolder";
import KeyUpAndDownEvent from "components/KeyUpAndDownEvent/KeyUpAndDownEvent";
import PictureGallery from "components/PictureGallery";
import ActivityReportsGallery from "components/PictureGallery/ActivityReportsGallery";
import { Tab, Tabs } from "components/Tab";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import { getAllFolders } from "containers/folders/redux/selectors";
import { fetchCustomerReportsPicturesAction } from "containers/lists/redux/actions";
import {
  getIsFetchingCustomerReportsPictures,
  getSelectedCustomerReportsPictures,
} from "containers/lists/redux/selectors";
import { getItemName } from "containers/lists/utils/getItemLabel";
import profileHasRight from "containers/workflows/subcategories/activities/utils/profileHasRight";
import useActions from "hooks/useActions";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import ICustomer from "model/entities/Customer";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IActivity, IWorkflow } from "model/entities/Workflow";

import CustomerActivityReports from "../CustomerActivityReports";
import CustomerMetaData from "../CustomerMetaData";
import CustomerNotes from "../CustomerNotes";
import { getErrorMessagesForItem } from "../utils";
import getItemGalleryPictures from "../utils/getItemGalleryPicture";
import { prepareFilters } from "../utils/prepareFilters";
import ItemForm from "./ItemForm";
import ItemInsights from "./ItemInsights";

export interface IAdditionnalProps {
  lists: IList[];
  listScope: LIST_SCOPE;
  schema: IListSchema[];
  workflows: IWorkflow[];
  activities: IActivity[];
}

export interface IItemDetailsDialog {
  item: IListItem;
  isCreation?: boolean;
  open?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  onClose: () => void;
  onConfirm: (item: IListItem) => void;
  list: IList;
  listScope: string;
  additionnalProps: IAdditionnalProps;
  viewMode: TViewMode;
  disableEdition?: boolean;
  hideArrows?: boolean;
}

const ItemDialog = ({
  item,
  isCreation = false,
  open,
  onPrev,
  onNext,
  onClose,
  onConfirm,
  additionnalProps,
  viewMode: initViewMode,
  list: selectedList,
  disableEdition,
  hideArrows,
}: IItemDetailsDialog) => {
  const currentId = item._id;

  const lang = useTranslations();

  const langKey =
    lang.containers.workflows.subCategories.activityReports.detailDialog;
  const [filterParams, setFilterParams] = useState({});

  const fetchCustomerReportsPictures = useActions(
    fetchCustomerReportsPicturesAction
  );
  const isFetchingPictures = useSelector(getIsFetchingCustomerReportsPictures);
  const selectedPictures = useSelector(getSelectedCustomerReportsPictures);
  const client = useSelector(getSelectedClient);

  const allowedEdition =
    !disableEdition &&
    profileHasRight(
      client.profile,
      client.web_access_right_profiles ?? [],
      "EDIT_ITEM"
    );

  const { schema } = selectedList;

  const onChangeFilters = (filters: any) => {
    setFilterParams(filters);
    fetchCustomerReportsPictures(currentId, filters);
  };

  const dashboards = useSelector(dashboardsComposedSelector);
  const filters = prepareFilters(
    additionnalProps.activities,
    additionnalProps.workflows,
    filterParams
  );
  const itemDashboards = _.filter(
    _.filter(dashboards, {
      type: DASHBOARD_TYPE.ON_LIST_ITEM,
      list_id: selectedList?.id,
    }),
    function (d) {
      return !d.is_statement;
    }
  );

  const [viewMode, setViewMode] = useState<TViewMode>(initViewMode);

  const validate = ({ attributes }: any) =>
    getErrorMessagesForItem({
      attributes,
      additionnalProps,
      lang,
      viewMode,
    });

  const formState = useFormState<IListItem>({
    initAttributes: item,
    validate,
  });

  const {
    attributes: itemState,
    errors,
    resetChanges,
    filesToUpload,
  } = formState;

  useEffect(() => {
    // to avoid infinite loop when passing item={{ _id: generateRandomId() }}
    if (viewMode === "CREATE") {
      return;
    }
    resetChanges({ attributes: item });
  }, [viewMode, item, resetChanges]);

  useEffect(() => {
    if (
      selectedList.list_type === LIST_TYPE.CUSTOMER &&
      viewMode !== "CREATE"
    ) {
      fetchCustomerReportsPictures(currentId);
    }
  }, [
    currentId,
    fetchCustomerReportsPictures,
    selectedList.list_type,
    viewMode,
  ]);

  const handleConfirm = () => {
    if (!_.isEmpty(filesToUpload)) {
      itemState["filesToUpload"] = filesToUpload;
    }
    onConfirm(itemState);
    resetChanges();

    if (initViewMode === "VIEW") {
      setViewMode("VIEW");
    } else {
      onClose();
    }
  };

  const handleCancel = () => {
    resetChanges();

    if (initViewMode === "VIEW") {
      setViewMode("VIEW");
    } else {
      onClose();
    }
  };

  const pictures = getItemGalleryPictures(itemState, schema);
  const itemsLang = lang.containers.lists.subCategories.items;
  const emptyItemPlaceholder = itemsLang.detailDialog.emptyPictures;
  const folderChips: ICustomChipData[] = [];
  const allFolders = useSelector(getAllFolders);

  _.forEach(itemDashboards, function (dashboard) {
    if (dashboard.hasOwnProperty("folder") && dashboard.folder !== "default") {
      const folderName =
        _.find(allFolders, { id: dashboard.folder })?.name ??
        dashboard.folder ??
        "";

      const chip: ICustomChipData = {
        tag: dashboard.folder ?? "",
        label: folderName,
      };

      if (!_.find(folderChips, chip)) {
        folderChips.push(chip);
      }
    }
  });

  const initTag = folderChips
    ? folderChips.length > 0
      ? folderChips[0].tag
      : undefined
    : undefined;
  const [selectedTag, setSelectedTag] = useState<string | undefined>(initTag);

  function handleChipClick(currentTag: string | undefined) {
    setSelectedTag(currentTag);
  }

  return (
    <KeyUpAndDownEvent
      onKeyUp={!hideArrows && viewMode === "VIEW" ? onPrev : undefined}
      onKeyDown={!hideArrows && viewMode === "VIEW" ? onNext : undefined}
    >
      <BasicDialog
        open={Boolean(open)}
        onClose={onClose}
        minWidth={800}
        maxWidth={1200}
        minHeight={"90vh"}
        alignTop
      >
        <BasicDialogTitle>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!hideArrows && viewMode === "VIEW" && allowedEdition && (
              <Box marginRight="24px">
                <BasicArrowIcon
                  filled
                  direction="up"
                  style={{ marginRight: "20px" }}
                  disabled={!onPrev}
                  onClick={onPrev}
                />

                <BasicArrowIcon
                  filled
                  direction="down"
                  disabled={!onNext}
                  onClick={onNext}
                />
              </Box>
            )}

            {viewMode === "CREATE" ? (
              <Box>{itemsLang.createEditModal.createTitleShort}</Box>
            ) : (
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                {getItemName(itemState)}
              </Typography>
            )}

            <Box>
              {viewMode !== "CREATE" && (
                <Link
                  target="_blank"
                  style={{
                    color: "inherit",
                  }}
                  // TODO: this is fragile ? Do not use editable name in URL ?
                  href={`/scanner/${itemState._id}/${
                    itemState._displayed_name ?? itemState._name
                  }`}
                >
                  <BasicScannerIcon />
                </Link>
              )}

              {viewMode === "VIEW" && allowedEdition && (
                <BasicEditIcon onClick={() => setViewMode("CREATE")} />
              )}

              <BasicCloseIcon onClick={onClose} />
            </Box>
          </Box>
        </BasicDialogTitle>

        <BasicDialogContent
          noOverflow={viewMode != "CREATE"}
          // Added viewMode so the scroll can be recomputed (seems to be stuck when going from view to CREATE/EDIT otherwise)
          key={`${itemState._id}-${viewMode}`}
        >
          {viewMode === "CREATE" ? (
            <ItemForm
              viewMode={viewMode}
              list={selectedList}
              formState={formState}
            />
          ) : (
            <Tabs shiftScrollbar={24}>
              <Tab id="details" label={langKey.details}>
                <ItemForm
                  viewMode={viewMode}
                  list={selectedList}
                  formState={formState}
                />

                {selectedList.list_type === LIST_TYPE.CUSTOMER && (
                  <CustomerMetaData customer={itemState as ICustomer} />
                )}
              </Tab>

              <Tab id="pictures" label={langKey.pictures}>
                {pictures.length > 0 ? (
                  <PictureGallery pictures={pictures} />
                ) : (
                  <DialogPlaceHolder
                    placeholderIcon={<FilterIcon />}
                    placeholderText={emptyItemPlaceholder}
                  />
                )}

                {selectedList.list_type === LIST_TYPE.CUSTOMER && (
                  <ActivityReportsGallery
                    isLoadingCustomerReportsPictures={isFetchingPictures}
                    customerReportsPictures={selectedPictures}
                    filters={filters}
                    onChangeFilters={onChangeFilters}
                  />
                )}
              </Tab>

              {!_.isEmpty(itemDashboards) && itemState._id ? (
                <Tab
                  id="insights"
                  label={
                    lang.containers.lists.subCategories.items.detailDialog
                      .insights
                  }
                >
                  <Box paddingBottom={"8px"}>
                    <CustomChips
                      data={folderChips}
                      selectedTag={selectedTag}
                      onChipClick={handleChipClick}
                      hideAll
                    />
                  </Box>

                  {_.map(
                    _.sortBy(
                      _.filter(itemDashboards, function (dashboard) {
                        if (selectedTag) {
                          return dashboard.folder === selectedTag;
                        }
                        return true;
                      }),
                      "created_at"
                    ),
                    (dashboard) => (
                      <ItemInsights
                        dashboard={dashboard}
                        itemId={itemState._id}
                        key={dashboard.id}
                      />
                    )
                  )}
                </Tab>
              ) : null}

              {selectedList?.id === "customer" ? (
                <Tab
                  id="notes"
                  label={
                    lang.containers.lists.subCategories.items.detailDialog.notes
                  }
                >
                  {itemState._notes && itemState._notes.length > 0 ? (
                    <CustomerNotes notes={itemState._notes} />
                  ) : (
                    <DialogPlaceHolder
                      placeholderIcon={<MessageIcon />}
                      placeholderText={itemsLang.detailDialog.emptyNotes}
                    />
                  )}
                </Tab>
              ) : null}

              {selectedList?.id === "customer" ? (
                <Tab
                  id="activities"
                  label={
                    lang.containers.workflows.subCategories.activities.subjects
                  }
                >
                  <CustomerActivityReports customerId={itemState._id} />
                </Tab>
              ) : null}
            </Tabs>
          )}
        </BasicDialogContent>

        {viewMode !== "VIEW" && (
          <BasicDialogActions>
            <div style={{ display: "flex", columnGap: "10px" }}>
              <Button
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
                disableElevation
              >
                {lang.genericTerms.cancel}
              </Button>

              <Button
                onClick={handleConfirm}
                disabled={!_.isEmpty(errors)}
                variant="contained"
                color="secondary"
                disableElevation
              >
                {isCreation
                  ? lang.actions.create
                  : lang.genericTerms.saveChanges}
              </Button>
            </div>
          </BasicDialogActions>
        )}
      </BasicDialog>
    </KeyUpAndDownEvent>
  );
};
export default ItemDialog;
