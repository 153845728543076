import React from "react";

import { Grid } from "@material-ui/core";

import { blue, darkgray, darkteal, White } from "assets/colors";
import { IKPI, KPI_TYPE } from "model/entities/Dashboard";

import QueryDisabledChart from "../Chart/QueryDisabledChart";
import TimeoutChart from "../Chart/TimeoutChart";
import PdfPrintableChartWrapper from "../Pdf/PdfPrintableChartWrapper";
import CustomScorecard from "./CustomScorecard";

const customScoreCard = (
  scorecard: IKPI,
  index: number,
  downloadDashboard: () => any
) => {
  let backgroundColor, fontColor;
  switch (index % 4) {
    case 0: {
      backgroundColor = darkteal;
      fontColor = White;
      break;
    }
    case 1: {
      backgroundColor = blue;
      fontColor = White;
      break;
    }
    case 2: {
      backgroundColor = darkgray;
      fontColor = White;
      break;
    }
    default: {
      backgroundColor = White;
      fontColor = darkgray;
      break;
    }
  }
  const style = {
    backgroundColor: backgroundColor,
    color: fontColor,
  };

  if (scorecard?.data?.error === "query_disabled") {
    return (
      <Grid item lg={3} md={6} sm={6} xs={12} key={scorecard.tag}>
        <QueryDisabledChart chart={scorecard} />
      </Grid>
    );
  }

  if (scorecard?.data?.error === "timeout") {
    return (
      <Grid item lg={3} md={6} sm={6} xs={12} key={scorecard.tag}>
        <TimeoutChart
          chartType={scorecard.type}
          downloadDashboard={downloadDashboard}
        />
      </Grid>
    );
  }

  return (
    <Grid item lg={3} md={6} sm={6} xs={12} key={scorecard.tag}>
      <PdfPrintableChartWrapper
        title={scorecard.title}
        type={KPI_TYPE.SCORECARD}
        width={25}
      >
        <CustomScorecard
          title={scorecard.title}
          description={scorecard.description}
          value={scorecard.data}
          unit={scorecard.unit}
          display_variation={scorecard.display_variation}
          variationValue={scorecard.variationData}
          style={style}
        />
      </PdfPrintableChartWrapper>
    </Grid>
  );
};

interface IScorecardContainerProps {
  scorecard: IKPI;
  index: number;
  downloadDashboard?: () => any;
}

export const ScorecardContainer: React.FunctionComponent<
  IScorecardContainerProps
> = ({ scorecard, index, downloadDashboard }) => {
  if (downloadDashboard) {
    return customScoreCard(scorecard, index, downloadDashboard);
  } else {
    return <></>;
  }
};

export default ScorecardContainer;
