import React, { useEffect, useState } from "react";

import _ from "lodash";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { IBoardProps, TEventPlaceHolder } from "../Board";
import { TCalendarEventFE } from "../redux/types";
import { computeEventsMap } from "../utils/computeEventsMap";
import { computeNewDraggedEventForDayOnlyCalendar } from "../utils/computeNewDraggedEventForDayOnlyCalendar";
import { generateWorkingDates } from "../utils/dateStepper";
import { getCalendarNumberOfDays, ICalendarProps } from "../WeeklyCalendar";
import WeeklyCalendarDayOnlyView from "./WeeklyCalendarDayOnlyView";

export interface IDayOnlyCalendarProps
  extends Pick<
      ICalendarProps,
      | "defaultEvents"
      | "startDate"
      | "width"
      | "minWidth"
      | "maxWidth"
      | "height"
      | "draggable"
      | "onChange"
      | "activeBoardItems"
      | "eventPlaceHolders"
    >,
    Pick<
      IBoardProps,
      | "eventPlaceHolders"
      | "activeBoardItems"
      | "onClickApproveEvent"
      | "onClickDeclineEvent"
      | "onClickDeleteEvent"
      | "onClickTimeSlot"
      | "onEditEvent"
    > {}
const WeeklyCalendarDayOnlyViewHandler: React.FC<IDayOnlyCalendarProps> = ({
  defaultEvents,
  onClickApproveEvent,
  onClickDeclineEvent,
  onClickDeleteEvent,
  onClickTimeSlot,
  eventPlaceHolders,
  activeBoardItems,
  onEditEvent,
  onChange,
  startDate,
}) => {
  const [eventsArray, setEventsArray] = useState<TCalendarEventFE[][]>([]);
  const [placeholders, setPlaceholders] = useState<TEventPlaceHolder[][]>([]);
  const [slotSize, setSlotSize] = useState(0);
  const client = useSelector(getSelectedClient);
  const { moment } = useMomentTimeZone();
  const DAYS_COUNT = getCalendarNumberOfDays(client);

  const dates = generateWorkingDates(
    moment(startDate).toDate(),
    DAYS_COUNT,
    moment
  );

  const handleDragEnd: OnDragEndResponder = (result) => {
    const newEvents = computeNewDraggedEventForDayOnlyCalendar({
      dropResult: result,
      events: defaultEvents,
      moment,
    });
    onChange && onChange(newEvents);
    update(newEvents);
  };

  function update(events: TCalendarEventFE[] = defaultEvents) {
    const { eventsArray, placeHoldersArray, sizes } = computeEventsMap({
      dates,
      events,
      eventPlaceHolders: eventPlaceHolders ?? [],
      momentObject: moment,
    });

    setEventsArray(eventsArray);
    setPlaceholders(placeHoldersArray);
    setSlotSize(Math.max(...sizes));
  }

  const dependencies = [
    JSON.stringify(dates),
    JSON.stringify(defaultEvents),
    _.toString(eventPlaceHolders),
  ];

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return (
    <WeeklyCalendarDayOnlyView
      dates={dates}
      events={eventsArray}
      onClickDeleteEvent={onClickDeleteEvent}
      onEditEvent={onEditEvent}
      activeBoardItems={activeBoardItems}
      minSlotCount={Math.max(slotSize, 20)}
      onClickTimeSlot={onClickTimeSlot}
      onClickApproveEvent={onClickApproveEvent}
      onClickDeclineEvent={onClickDeclineEvent}
      onClickDelete={onClickDeleteEvent}
      handleDragEnd={handleDragEnd}
      placeholders={placeholders}
    />
  );
};

export default WeeklyCalendarDayOnlyViewHandler;
