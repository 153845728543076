import {
  ACTION_CODE,
  ACTION_TYPE,
  DETAILED_ACTION_CODE,
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  IAccessRightProfile,
  OBJECT_CATEGORY,
} from "fieldpro-tools";
import {
  getDefaultProfiles,
  getOptimetriksAccessRightProfiles,
  isOptimetriksRole,
  OPTIMETRIKS_ROLES,
} from "fieldpro-tools/dist/src/accessRights";
import _ from "lodash";

export const getDetailedActionName = (
  actionType: DETAILED_ACTION_TYPE | string,
  entityName?: DETAILED_OBJECT_CATEGORY,
  isPlural?: boolean,
  isCsvUpload?: boolean
) => {
  return `${actionType}${entityName ? `_${entityName}` : ""}${
    isPlural ? "S" : ""
  }${isCsvUpload ? "_FROM_CSV" : ""}`;
};

export const EMPTY_ACCESS_RIGHT_PROFILE: IAccessRightProfile = {
  name: "empty",
  access_all_resources: false,
  actions: {},
  action_details: {},
};

export const getAccessRightProfile = (
  profiles?: IAccessRightProfile[],
  role?: string
): IAccessRightProfile => {
  const profileWithoutManualOptimetriksAccess = _.filter(
    profiles,
    (profile) => !isOptimetriksRole(profile.name)
  );
  const allAccessRights = [
    ...getOptimetriksAccessRightProfiles(),
    ...profileWithoutManualOptimetriksAccess,
  ] as IAccessRightProfile[];

  const profile = _.find(allAccessRights, (profile) => role === profile.name);
  return profile
    ? {
        ...profile,
        actions: {
          ...profile.actions,
          ACCESS_ALL_RESOURCES: profile.access_all_resources, // used in some parts of the code, could be refactored
        },
      }
    : EMPTY_ACCESS_RIGHT_PROFILE;
};

// Keeping this export for legacy reasons
// You can import these from fieldpro-tools directly
export {
  ACTION_CODE,
  ACTION_TYPE,
  DETAILED_ACTION_CODE,
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  getDefaultProfiles,
  isOptimetriksRole,
  OBJECT_CATEGORY,
  OPTIMETRIKS_ROLES,
};
