import React from "react";

import {
  IQuerySettings,
  isOptimetriksRole,
  META_EXPRESSION_SCOPE,
  META_EXPRESSION_TARGET_TYPE,
} from "fieldpro-tools";
import { useSelector } from "react-redux";

import { getPrivilege } from "containers/authentication/redux/selector";
import useTranslations from "hooks/useTranslations";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";
import { getAllowedMetaExpressionScopes } from "utils/metaExpressions";

import InputSelect from "../InputSelect";
import InputText, { INPUT_TEXT_TYPE } from "../InputText/InputText";
import { CALLED_FROM } from "./MetaExpressionWizard";

/**
 * First step: scope selection + title of the meta expression
 */
export interface IMetaExpressionWizardFirstStepProps {
  scope?: META_EXPRESSION_SCOPE;
  shouldMarkError: any;
  labelToDisplay: any;
  title: string;
  target_type?: META_EXPRESSION_TARGET_TYPE;
  target_content?: string;
  calledFrom?: CALLED_FROM;
  includeCustomerScope?: boolean;
  includeIntegRecordScope?: boolean;
  onChange: (value: any, name: string) => void;
  sqlQueryStatus?: IQuerySettings;
}

export const MetaExpressionWizardFirstStep: React.FunctionComponent<
  IMetaExpressionWizardFirstStepProps
> = ({
  scope,
  shouldMarkError,
  labelToDisplay,
  title,
  calledFrom,
  includeCustomerScope,
  includeIntegRecordScope,
  target_type,
  target_content,
  onChange,
}) => {
  const lang = useTranslations();
  const role = useSelector(getPrivilege);
  const isOptiRole = isOptimetriksRole(role);

  const allowedScopes = getAllowedMetaExpressionScopes(
    calledFrom,
    includeCustomerScope,
    includeIntegRecordScope
  );

  const scopeOptions = getOptionsInputSelect({
    listKeys: allowedScopes,
    lang: lang.components.metaExpressions.scope,
  });

  return (
    <div>
      <InputText
        error={shouldMarkError("title")}
        name="title"
        lang={labelToDisplay.inputTitle}
        defaultValue={title}
        onChange={onChange}
        required={true}
        replaceSpaces
        lowercase
      />
      {isOptiRole && (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("target_type")}
          name="target_type"
          lang={labelToDisplay.inputTargetType}
          options={Object.entries(META_EXPRESSION_TARGET_TYPE)
            .filter(([_key, value]) => value !== "TEXT")
            .map(([key, value]) => ({
              key: key,
              label: value,
            }))}
          value={target_type}
          onChange={onChange}
        />
      )}
      {(!target_type ||
        target_type === META_EXPRESSION_TARGET_TYPE.VARIABLE) && (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("scope")}
          name="scope"
          lang={labelToDisplay.inputScope}
          options={scopeOptions}
          value={scope}
          onChange={onChange}
        />
      )}
      {isOptiRole &&
        target_type &&
        [
          META_EXPRESSION_TARGET_TYPE.SQL,
          META_EXPRESSION_TARGET_TYPE.TEXT,
        ].includes(target_type) && (
          <InputText
            error={shouldMarkError("target_content")}
            name="target_content"
            lang={labelToDisplay.inputTargetContent}
            defaultValue={target_content}
            onChange={onChange}
            required={true}
            type={
              target_type === META_EXPRESSION_TARGET_TYPE.SQL
                ? INPUT_TEXT_TYPE.SQL
                : INPUT_TEXT_TYPE.TEXT
            }
          />
        )}
    </div>
  );
};
