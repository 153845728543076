import React, { useEffect, useState } from "react";

import _ from "lodash";

import { fetchListOptionsAction } from "containers/lists/redux/actions";
import { useActions } from "hooks";
import { IOption } from "model/application/components";

import { IFilter } from "../../../../model/application/Filter";
import BaseMultipleChoiceOnOptions from "./BaseMultipleChoiceOnOptions";

/**
 * A ready to go MCOL filter on a list built on top of BaseMultipleChoiceOnOptions
 * since list filters are used often this saves us the trouble
 */
export interface IMultipleChoiceOnListFilterProps {
  filter: IFilter;
  onChangeFilter: (
    name: string,
    value: Record<string, string | Object>
  ) => void;
  searchDebounceDuration?: number;
  listId: string;
}

const MultipleChoiceOnListFilter: React.FC<
  IMultipleChoiceOnListFilterProps
> = ({ filter, onChangeFilter, listId, searchDebounceDuration }) => {
  const fetchListOptions = useActions(fetchListOptionsAction);
  const [options, setOptions] = useState<IOption<string>[] | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  async function handleSearch(searchTerm: string) {
    setSearchTerm(searchTerm);
  }

  function getFilterOptions(): IOption<string, string>[] {
    const opts = options || filter.options || [];
    if (_.isEmpty(searchTerm)) {
      return _.compact(_.uniqBy(opts, "key"));
    }
    return opts;
  }

  const opt = getFilterOptions();
  const preparedFilter: IFilter = {
    ...filter,
    onSearch: handleSearch,
    options: opt,
  };
  useEffect(() => {
    async function updateInitialOptions() {
      setLoading(true);
      const newOptions = await fetchListOptions(listId, {
        filters: {},
        search: searchTerm,
      });
      setOptions(newOptions.map((opt) => ({ key: opt.id, label: opt.name })));

      setLoading(false);
    }
    updateInitialOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, searchTerm]);

  return (
    <BaseMultipleChoiceOnOptions
      filter={preparedFilter}
      onChangeFilter={onChangeFilter}
      searchDebounceDuration={searchDebounceDuration}
      loading={loading}
    />
  );
};
export default MultipleChoiceOnListFilter;
