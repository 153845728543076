import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import _ from "lodash";

import { IOption } from "model/application/components";

export interface IInputCheckListProps {
  options?: IOption[];
  selectedOptions: IOption[];
  onAddOption: (option: IOption) => void;
  onRemoveOption: (option: IOption) => void;
}
function InputCheckList({
  onAddOption,
  onRemoveOption,
  options = [],
  selectedOptions,
}: Readonly<IInputCheckListProps>) {
  return (
    <List>
      {_.map(_.uniqBy(options, "key"), (option) => {
        const selected = _.find(selectedOptions, { key: option.key });
        return (
          <ListItem
            onClick={() => {
              if (selected) {
                onRemoveOption(option);
              } else {
                onAddOption(option);
              }
            }}
            style={{
              cursor: "pointer",
            }}
            button
            key={option.key}
          >
            <ListItemIcon style={{ minWidth: "35px" }}>
              <Checkbox
                onClick={() => {
                  if (selected) {
                    onRemoveOption(option);
                  } else {
                    onAddOption(option);
                  }
                }}
                checked={!!selected}
                tabIndex={-1}
                edge="start"
              />
            </ListItemIcon>
            <ListItemText
              primary={option.label}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px",
                  width: "300px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default InputCheckList;
