import _ from "lodash";
import * as XLSX from "xlsx";

import { TABLE_AGGREGATOR } from "components/Table/model";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { IClient } from "model/entities/Client";

import { isWorkspaceTemplate } from "./clients/utils";

/**
 * Checks if the email is valid
 * @param {String} email
 * @returns {Boolean} returns True if the email is valid
 */
export function isEmailValid(email: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email);
}

export const transposeLocalTimeToUTCTime = (localTime: Date) => {
  const timezoneOffset = new Date(localTime).getTimezoneOffset();

  const transposedDate = new Date(
    new Date(localTime).getTime() - timezoneOffset * 1000 * 60
  );
  return transposedDate;
};

/**
 * Checks if the phone number is valid
 * @param {String} phone
 * @returns {Boolean} returns True if the phone number is valid
 */
export function isPhoneValid(phone: string, phoneRegex?: string): boolean {
  if (phoneRegex) {
    // IF WE WANT TO USE REGEX OPTIONS

    // const flags = phoneRegex.replace(/.*\/([gimy]*)$/, "$1");
    // const pattern = phoneRegex.replace(
    //   new RegExp("^/(.*?)/" + flags + "$"),
    //   "$1"
    // );
    // const regex = new RegExp(pattern, flags);

    // ELSE
    const regex = new RegExp(phoneRegex);
    return regex.test(phone);
  }
  return /^[+]+[0-9]{4,17}$/i.test(phone);
}

/**
 * Checks if the string has only alphanumerical and underscore character
 * @param {String} text
 */
export function isAlphanumericalWithUnderscores(text: string): boolean {
  const regexp = /^[a-z0-9_]*$/;
  return regexp.test(text);
}

/**
 * Checks if the string has only alphanumerical and underscore character
 * @param {String} text
 */
export function isAlphanumericalWithoutUnderscores(text: string): boolean {
  const regexp = /^[a-z0-9]*$/;
  return regexp.test(text);
}

/**
 * Checks if the string has only alphanumerical or underscore characters
 * (and starts with a letter)
 * @param {String} text
 */
export function isAlphanumericalStartingWithLetter(text: string): boolean {
  const regexp = /^[a-z][a-z0-9_]*$/;
  return regexp.test(text);
}

/**
 * Checks if the string has only alphanumerical and underscore character
 * (and starts with underscore)
 * @param {String} text
 */
export function isAlphanumericalStartingWithUnderscore(text: string): boolean {
  const regexp = /^_[a-z0-9_]*$/;
  return regexp.test(text);
}

/**
 * Filters the values and tests the
 * @param {String} value Value to test
 * @param {String} pattern Pattern to test for
 * @returns {Boolean} True if the pattern matches False otherwise
 */
export function regexTestValue(value: string, pattern: string): boolean {
  const re = new RegExp(pattern, "gi");
  return re.test(value);
}
/**
 * Convert agiven Base64 token string to object
 * @param {String} val
 * @returns {Object} JSON parsed object
 */
export const unBase64ToObj = (val: string): string => {
  const str = Buffer.from(val, "base64").toString("utf-8");
  return JSON.parse(str);
};

/**
 * Convert data in CSV (comma separated value) format to a javascript array.
 *
 * Values are separated by a comma, or by a custom one character delimeter.
 * Rows are separated by a new-line character.
 *
 * Leading and trailing spaces and tabs are ignored.
 * Values may optionally be enclosed by double quotes.
 * Values containing a special character (comma's, double-quotes, or new-lines)
 *   must be enclosed by double-quotes.
 * Embedded double-quotes must be represented by a pair of consecutive
 * double-quotes.
 *
 * Example usage:
 *   var csv = '"x", "y", "z"\n12.3, 2.3, 8.7\n4.5, 1.2, -5.6\n';
 *   var array = csv2array(csv);
 *
 * Author: Jos de Jong, 2010
 *
 * @param {string} data      The data in CSV format.
 * @param {string} delimeter [optional] a custom delimeter. Comma ',' by default
 *                           The Delimeter must be a single character.
 * @return {Array} array     A two dimensional array containing the data
 * @throw {String} error     The method throws an error when there is an
 *                           error in the provided data.
 */
export const csv2array = (data: string, delimeter: string): any[] => {
  // Retrieve the delimeter
  if (delimeter === undefined) delimeter = ",";
  if (delimeter && delimeter.length > 1) delimeter = ",";

  // initialize variables
  const newline: string = "\n";
  const eof: string = "";
  let i: number = 0;
  let c = data.charAt(i);
  let row: number = 0;
  const array: any[] = [];

  while (c !== eof) {
    // skip whitespaces
    while (c === " " || c === "\t" || c === "\r") {
      c = data.charAt(++i); // read next char
    }

    // get value
    let value: any = "";
    if (c === '"') {
      // value enclosed by double-quotes
      c = data.charAt(++i);

      do {
        if (c !== '"') {
          // read a regular character and go to the next character
          value += c;
          c = data.charAt(++i);
        }

        if (c === '"') {
          // check for escaped double-quote
          const cnext = data.charAt(i + 1);
          if (cnext === '"') {
            // this is an escaped double-quote.
            // Add a double-quote to the value, and move two characters ahead.
            value += '"';
            i += 2;
            c = data.charAt(i);
          }
        }
      } while (c !== eof && c !== '"');

      if (c === eof) {
        throw new Error("Unexpected end of data, double-quote expected");
      }

      c = data.charAt(++i);
    } else {
      // value without quotes
      while (
        c !== eof &&
        c !== delimeter &&
        c !== newline &&
        c !== "\t" &&
        c !== "\r"
      ) {
        value += c;
        c = data.charAt(++i);
      }
    }

    // add the value to the array
    if (array.length <= row) array.push([]);
    array[row].push(value);

    // skip whitespaces
    while (c === " " || c === "\t" || c === "\r") {
      c = data.charAt(++i);
    }

    // go to the next row or column
    if (c === delimeter) {
      // to the next column
      //col++;
    } else if (c === newline) {
      // to the next row
      //col = 0;
      row++;
    } else if (c !== eof) {
      // unexpected character
      throw new Error("Delimiter expected after character " + i);
    }

    // go to the next character
    c = data.charAt(++i);
  }
  // special case of the last cell being empty:
  if (array[0].length !== array[array.length - 1].length) {
    array[array.length - 1][array[0].length - 1] = "";
  }
  return array;
};

export const clone = (obj: any): any => {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  if (obj.hasOwnProperty("file") && obj.hasOwnProperty("fileName")) {
    if (obj.file instanceof File) {
      return {
        file: new File([obj.file], obj.fileName, { type: obj.file.type }),
        fileName: obj.fileName,
      };
    } else {
      return { file: [], fileName: "" };
    }
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const formatTemplateString = (
  pattern: string | undefined,
  element: {
    [key: string]: string;
  }
) => {
  if (!pattern && !element.label) return "/!\\ No Name";
  if (!pattern) return element.label;
  let result = "";
  const splittedPattern = pattern.split("$");
  for (let i = 0; i < splittedPattern.length; i++) {
    if (i % 2 === 1) {
      // even idx: we replace the string per the value of the attribute
      // if there is "." char, we split
      const attrs = splittedPattern[i].split(".");
      switch (attrs.length) {
        case 1:
          result += element[splittedPattern[i]];
          break;
        case 2:
          result += element[attrs[0]][attrs[1]];
          break;
        case 3:
          result += element[attrs[0]][attrs[1]][attrs[2]];
          break;
        default: /** nothing */
      }
    } else {
      // odd idx: we display the string as it is
      result += splittedPattern[i];
    }
  }
  if (result.length === 0) {
    return "/!\\ No Name";
  }
  return result;
};

const chars = "abcdefghijklmnopqrstuvwxyz";

export const generateRandomId = () => {
  let lastTimestamp = 0;
  let currentMsIndex = 0;
  const currentTimestamp = Date.now();
  if (currentTimestamp !== lastTimestamp) {
    currentMsIndex = 1e6 + Math.floor(Math.random() * 100000);
    lastTimestamp = currentTimestamp;
  }
  currentMsIndex += 111;
  const id1 = currentTimestamp.toString(36) + currentMsIndex.toString(36);
  const id2 = id1.split("").reverse().join("");
  let prefix = "";
  for (let i = 0; i < 4; i++) {
    prefix += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return prefix + id2;
};

export const aggregateValues = (
  values: any[],
  aggregator: TABLE_AGGREGATOR
): number | string => {
  switch (aggregator) {
    case TABLE_AGGREGATOR.SUM: {
      return values.reduce(
        (acc: number, curr: any) => (isNaN(curr) ? acc : acc + curr),
        0
      );
    }
    case TABLE_AGGREGATOR.MEAN: {
      if (values.length === 0) return 0;
      return (
        values.reduce(
          (acc: number, curr: any) => (isNaN(curr) ? acc : acc + curr),
          0
        ) / values.length
      );
    }
    case TABLE_AGGREGATOR.MIN: {
      const min = values.reduce(
        (acc: number, curr: any) =>
          isNaN(curr) ? acc : acc > curr ? curr : acc,
        Infinity
      );
      return min === Infinity ? 0 : min;
    }
    case TABLE_AGGREGATOR.MAX: {
      const max = values.reduce(
        (acc: number, curr: any) =>
          isNaN(curr) ? acc : acc < curr ? curr : acc,
        -Infinity
      );
      return max === -Infinity ? 0 : max;
    }
    default: {
      return values[0];
    }
  }
};

export const extractHeaderActions = (actions: ITableAction[]) => {
  const downloadAction = _.find(actions, { actionType: ACTION_TYPE.DOWNLOAD });
  const createAction = _.find(actions, {
    actionType: ACTION_TYPE.CREATE,
  });

  const createMultipleAction = _.find(actions, {
    actionType: ACTION_TYPE.CREATE_MULTIPLE,
  });

  const tableActions = _.reject(actions, (action) => {
    return _.includes(
      [ACTION_TYPE.DOWNLOAD, ACTION_TYPE.CREATE, ACTION_TYPE.CREATE_MULTIPLE],
      action.actionType
    );
  });

  return {
    downloadAction,
    createAction,
    createMultipleAction,
    tableActions,
  };
};

export const hasCreatePermission = (actions: any[]) => {
  const actionsFilter = _.filter(actions, (act) =>
    _.includes(
      [ACTION_TYPE.CREATE, ACTION_TYPE.CREATE_MULTIPLE],
      act.actionType
    )
  );
  return _.size(actionsFilter) > 0;
};

export type TSheetData = {
  name: string;
  rows: string[][];
};

export function downloadInMultipleSheetUtils(
  data: TSheetData[],
  fileNameWithExtension: string
) {
  try {
    // Create a new empty Excel workbook
    const wb = XLSX.utils.book_new();

    // Add each rows in his sheet in the excel file
    _.map(data, (dat) => {
      const sheet = XLSX.utils.aoa_to_sheet(dat.rows);

      // The maximum size of sheet names in Excel is 32 characters
      const sheetName: string =
        _.size(dat.name) <= 31 ? dat.name : dat.name.slice(-31);

      //4th parameter permit to increment a sheet name if it exist already
      XLSX.utils.book_append_sheet(wb, sheet, sheetName, true);
    });

    // Download the Excel file
    XLSX.writeFile(wb, fileNameWithExtension);
  } catch (error) {
    console.error(error);
    return;
  }
}

// TODO: duplicate definition in project
export function hexToRgb(hex: string) {
  // Delete character # if there is present
  hex = hex.replace(/^#/, "");

  // Separate the hexadecimal chain into three parts (R, G and B)
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Turn the color in RGB format
  return `rgb(${r}, ${g}, ${b})`;
}

export const TEST_APP_VERSION = "1927-01-01T-00-00";

export const getAppVersion = () => {
  if (process.env.NODE_ENV === "test") {
    return TEST_APP_VERSION;
  }

  const versionText = process.env.REACT_APP_SMALA_VERSION;
  if (!versionText) {
    return "";
  }
  const index = versionText.indexOf("20");
  const version = versionText.substring(index);
  return version;
};

/**
 * Returns true of the element is marked as "from_template"
 * (but not in a workspace template itself)
 */
export const isFromTemplate = (element: object, client: IClient) => {
  // Double check on nature of client
  if (isWorkspaceTemplate(client)) {
    return false;
  }
  return !!element["from_template"];
};

export const stringToBoolean = (str: string | boolean): boolean | undefined => {
  if (_.isBoolean(str)) return str;
  if (str && str.toLowerCase().trim() === "true") return true;
  if (str && str.toLowerCase().trim() === "false") return false;
  return undefined;
};

export const MAX_CHARACTERS_ALLOWED = 25;

export const isLengthOverLimit = (
  character?: string,
  limit = MAX_CHARACTERS_ALLOWED
) => {
  return _.size(character) > limit;
};

export const areArraysEqual = (arr1: string[], arr2: string[]) => {
  return (
    JSON.stringify(arr1.sort((a, b) => a.localeCompare(b))) ===
    JSON.stringify(arr2.sort((a, b) => a.localeCompare(b)))
  );
};
