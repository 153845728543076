/**
 * @notes: reducer for the authentication container
 */

import { IAccessRightProfile } from "fieldpro-tools";

import apiService from "api/apiService";
import { ISignedInUser } from "model/entities/User";
import { TAvailableActions } from "redux/actionTypes/appActionTypes";
import { IAction, IActionError } from "redux/store/model";

import { insertNewItemToArr } from "../../../utils/reducerUtils";
import {
  ILoginRequestSuccessAction,
  ISetAccessRightProfileSuccessAction,
  ISetActionsAvailableSuccessAction,
} from "./actionCreator";
import * as types from "./actionTypes";
import initialState from "./initialState";

interface IScreenMessage {
  show: boolean;
  lastShown: Date | null;
}
export interface IAuthenticationState {
  loggingOut: boolean;
  SigningUp: boolean;
  smalaToken: null | string;
  attempts: number;
  isPasswordRecovering: boolean;
  user: ISignedInUser;
  loginError: boolean;
  errorMessage: string;
  availableActions: TAvailableActions;
  accessRightProfile: IAccessRightProfile;
  retryWait: boolean;
  errors: IAction[];
  shouldChangePassword?: boolean;
  isChangingPassword?: boolean;
  lastCallToCheckVersion: Date;
  isUsingLastVersion: boolean;
  lang: "en" | "fr" | "es";
  screenMessages: {
    displaySwitchToWebMessage: IScreenMessage;
  };
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.SIGNUP_REQUEST_BEGIN:
      return {
        ...state,
        SigningUp: true,
      };

    case types.SIGNUP_REQUEST_FAILURE:
      return {
        ...state,
        SigningUp: false,
        errors: insertNewItemToArr(state.errors, action),
      };
    case types.SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        SigningUp: false,
      };

    case types.LOGIN_REQUEST_BEGIN:
      return {
        ...state,
        retryWait: false,
      };

    case types.LOGIN_REQUEST_SUCCESS: {
      const {
        token,
        id,
        name,
        userEmail,
        created_at,
        keepSignedIn,
        picture,
        shouldChangePassword,
      } = action as ILoginRequestSuccessAction;

      apiService.setToken(token);

      return {
        ...state,
        loginError: false,
        smalaToken: token,
        shouldChangePassword,
        user: {
          id,
          name,
          email: userEmail,
          isAuthenticated: true,
          keepSignedIn: keepSignedIn,
          createdAt: created_at,
          picture,
        },
      };
    }

    case types.LOGIN_REQUEST_FAILURE: {
      const { error } = action as IActionError;
      const { error: dbError } = error;

      const wrongEmailPasswordMessage =
        error?.default_error_message ??
        "The information provided is incorrect. Please try again.";

      const validationError =
        "Validation error. Please make sure the information provided you are sending is a valid one.";

      let errorMessage = wrongEmailPasswordMessage;

      if (dbError?.message === "Network Error") {
        errorMessage = "Network Error (please check your connection)";
      } else if (
        dbError?.response?.data?.data?.error?.name === "ValidationError"
      ) {
        errorMessage = validationError;
      }

      const attemptsCount = dbError?.value;
      return {
        ...state,
        loginError: true,
        attempts: attemptsCount ?? 0,
        errorMessage,
        retryWait:
          errorMessage === wrongEmailPasswordMessage && attemptsCount === 3
            ? true
            : false,
        errors: insertNewItemToArr(state.errors, action),
      };
    }

    case types.STOP_WAITING:
      return {
        ...state,
        attempts: 0,
        retryWait: false,
      };
    case types.LOGOUT_REQUEST_BEGIN:
      return {
        ...state,
        loggingOut: true,
      };

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.LOGOUT_REQUEST_FAILURE:
      return {
        ...state,
        loggingOut: false,
        smalaToken: null,
        user: {
          isAuthenticated: false,
          email: "",
          keepSignedIn: false,
        },
        errors: insertNewItemToArr(state.errors, action),
      };

    case types.RECOVER_PASSWORD_BEGIN:
      return {
        ...state,
        isPasswordRecovering: true,
      };

    case types.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordRecovering: false,
      };

    case types.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordRecovering: false,
      };
    }

    case types.CHANGE_LANG_SUCCES: {
      const { lang } = action as any; // FIXME: write a real type
      return {
        ...state,
        lang,
      };
    }
    case types.CHANGE_PROFILE_PICTURE_SUCCESS: {
      const { picture } = action as any;
      const user = {
        ...state.user,
        picture,
      };
      return {
        ...state,
        user,
      };
    }

    case types.SET_ACTIONS_AVAILABLE_SUCCESS: {
      const { availableActions } = action as ISetActionsAvailableSuccessAction;
      return {
        ...state,
        availableActions,
      };
    }

    case types.SET_ACCESS_RIGHT_PROFILE_SUCCESS: {
      const { accessRightProfile } =
        action as ISetAccessRightProfileSuccessAction;
      return {
        ...state,
        accessRightProfile,
      };
    }

    case types.GET_LAST_VERSION_SUCCESS: {
      const { isUsingLastVersion } = action as any;
      return {
        ...state,
        lastCallToCheckVersion: new Date(),
        isUsingLastVersion,
      };
    }
    case types.DISPLAY_SWITCH_TO_WEB_MESSAGE: {
      const { show } = action as any;
      return {
        ...state,
        screenMessages: {
          ...state.screenMessages,
          displaySwitchToWebMessage: {
            show,
            lastShown: show ? new Date() : null,
          },
        },
      };
    }

    case types.CHANGE_PASSWORD_BEGIN: {
      return {
        ...state,
        isChangingPassword: true,
      };
    }

    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        shouldChangePassword: false,
        isChangingPassword: false,
      };
    }

    case types.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        isChangingPassword: false,
      };
    }
    default:
      return state;
  }
}
