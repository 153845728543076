import { Box } from "@material-ui/core";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import MenuButton from "components/Buttons/MenuButton";
import { useTranslations } from "hooks";

import { EventColors } from "../styles";

interface IEventStatusMenuButtonProps {
  event: TCalendarEvent;
  onChangeStatus?: (
    events: TCalendarEvent[],
    status: CalendarEventStatus
  ) => void;
}
const EventStatusMenuButton: React.FC<IEventStatusMenuButtonProps> = ({
  event,
  onChangeStatus,
}) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal.inputStatus
      .options;

  const getOptions = (currentStatus?: CalendarEventStatus) => {
    const viableStatuses = [
      CalendarEventStatus.PENDING,
      CalendarEventStatus.PLANNED,
      CalendarEventStatus.DECLINED,
      CalendarEventStatus.OUTDATED,
    ].filter((status) => status !== currentStatus);

    return _.map(viableStatuses, (status) => ({
      label: (
        <Box
          fontSize={"12px"}
          style={{
            backgroundColor: EventColors.fillColor[status],
            color: EventColors.statusText[status],
          }}
          padding={"0px 8px"}
          borderRadius={"4px"}
        >
          {_.upperFirst(_.lowerCase(langKey[status]))}
        </Box>
      ),
      key: status,
      onClick: () => {
        onChangeStatus?.([event], status);
      },
    }));
  };

  const options = getOptions(event.status);
  return (
    <MenuButton
      key={event.id}
      options={options}
      disabled={event.status === CalendarEventStatus.COMPLETED}
      label={_.upperFirst(
        _.lowerCase(langKey[event.status ?? CalendarEventStatus.PENDING])
      )}
      buttonProps={{
        style: {
          height: "30px",
          color: EventColors.statusText[event.status ?? "default"],
          backgroundColor: EventColors.fillColor[event.status ?? "default"],
          fontSize: "12px",
        },
      }}
    />
  );
};

export default EventStatusMenuButton;
