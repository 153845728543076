import { Box } from "@material-ui/core";
import { OBJECT_CATEGORY } from "fieldpro-tools";
import { IActionDetails } from "fieldpro-tools/src/types";
import _ from "lodash";

import { GreyDark } from "assets/colors";

import InputAccessRights from "./InputAccessRights";
interface IDisplayProfileActions {
  childOptions: string[];
  fatherOption: string;
  fatherOptionIsChecked?: boolean;
  fatherOptionIsDisabled?: boolean;
  sectionTitle: string;
  defaultStatus: object;
  category: OBJECT_CATEGORY;
  actionDetails: { [s: string]: IActionDetails };
  onChange: (value: any, name: string) => void;
  onChangeSpecificRules: (value: any, name: string) => void;
  isMobile?: boolean;
}

const DisplayProfileActions = (props: IDisplayProfileActions) => {
  const {
    childOptions,
    fatherOption,
    defaultStatus,
    sectionTitle,
    fatherOptionIsChecked,
    category,
    actionDetails,
    onChange,
    onChangeSpecificRules,
    isMobile,
  } = props;

  return (
    <Box marginTop="-30px">
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: GreyDark,
          height: "24px",
          lineHeight: 1.2,
          margin: "43px 203px 0px 0",
        }}
      >
        {sectionTitle}
      </div>

      <Box marginLeft={"-13px"}>
        {/* Father option */}
        <InputAccessRights
          actionName={fatherOption}
          defaultStatus={defaultStatus}
          sectionTitle={sectionTitle}
          category={category}
          onChange={onChange}
          onChangeSpecificRules={onChangeSpecificRules}
          actionDetails={actionDetails[fatherOption]}
          isMobile={isMobile}
        />

        {/* Children options */}
        {fatherOptionIsChecked && (
          <Box>
            {_.map(childOptions, (action) => (
              <InputAccessRights
                key={action}
                actionName={action}
                defaultStatus={defaultStatus}
                sectionTitle={sectionTitle}
                category={category}
                onChange={onChange}
                onChangeSpecificRules={onChangeSpecificRules}
                actionDetails={actionDetails[action]}
                isMobile={isMobile}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DisplayProfileActions;
