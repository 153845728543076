const DEFAULT_DATE_TIME_FORMAT = "dd/mm/yyyy hh:MMtt";
const DEFAULT_DATE_FORMAT = "dd/mm/yyyy";
const DEFAULT_TIME_FORMAT = "hh:MMtt";

import dateFormat from "dateformat";

export const cleanDirtyDate = (
  dirtyDate: string | Date | undefined,
  formattingFunction: (lang?: any) => string
) => {
  let result = "";
  if (!dirtyDate || dirtyDate === "") {
    return null;
  }
  try {
    let date: Date;
    let dateStringCorrected = dirtyDate;
    if (typeof dirtyDate === "string" || dirtyDate instanceof String) {
      // if dirtyDate is a string, we run some extra precaution steps
      if (dirtyDate.split("/").length === 3) {
        const split = dirtyDate.split("/");
        if (split[2].length === 4) {
          // we assume we are in a European way of presenting date: DD/MM/YYYY
          dateStringCorrected = `${split[2]}-${split[1]}-${split[0]}`;
        }
      }
      date = new Date(dateStringCorrected);
    } else {
      date = dirtyDate;
    }
    result = dateFormat(date, formattingFunction());
  } catch (e: any) {
    // unknown date. We return an error.
    return "/!\\";
  }
  return result;
};

// TODO: One day, use a lang arg to change the default format
export const getDefaultDateTimeFormat = (_lang?: any) => {
  return DEFAULT_DATE_TIME_FORMAT;
};

export const getDefaultDateFormat = (_lang?: any) => {
  return DEFAULT_DATE_FORMAT;
};

export const getDefaultTimeFormat = (_lang?: any) => {
  return DEFAULT_TIME_FORMAT;
};

export const getDateSafe = (dateStr: string | null | undefined) => {
  if (!dateStr) {
    return undefined;
  }
  try {
    const date = new Date(dateStr);
    if (isValidDate(date)) {
      return date;
    }
  } catch {
    return undefined;
  }
};

export const isValidDate = (date: any): boolean => {
  return date instanceof Date && !!date.getDate();
};
export const isValidDateString = (date: any): boolean => {
  if (date instanceof String || typeof date === "string") {
    try {
      const d = new Date(date as any);
      return isValidDate(d);
    } catch (_e) {
      return false;
    }
  }
  return isValidDate(date);
};

export const dateWithoutTimezone = (date: Date): Date => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset);
  return withoutTimezone;
};
