import React, { ComponentProps, JSXElementConstructor, useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";

import ScrollEdgeListener from "components/ScrollEdgeListener";

type TDynamicList<T extends JSXElementConstructor<any> = typeof Box> = {
  buildMenuItemFunction: (index: number) => React.ReactNode;
  listSize: number;
  maxItems: number;
  Component?: T;
} & ComponentProps<T>;

function DynamicList<T extends JSXElementConstructor<any> = typeof Box>({
  Component = Box,
  buildMenuItemFunction,
  listSize,
  maxItems,
  ...props
}: TDynamicList<T>) {
  const [itemsToShow, setItemsToShow] = useState(Math.min(listSize, maxItems));

  return (
    <Component {...props}>
      {_.times(itemsToShow, (index) => buildMenuItemFunction(index))}
      <ScrollEdgeListener
        callback={() => {
          if (itemsToShow < listSize) {
            setItemsToShow(Math.min(itemsToShow + maxItems, listSize));
          }
        }}
      />
    </Component>
  );
}

export default DynamicList;
