import { saveAs } from "file-saver";
import JSZip from "jszip";
// TODO create manually definition type file for jszip-utils
// @ts-ignore
import JSZipUtils from "jszip-utils";
import _ from "lodash";
import moment from "moment";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { getLocationValue } from "containers/lists/utils";
import { getItemLabel } from "containers/lists/utils/getItemLabel";
import IPreparedPictureFilterParams from "containers/pictures/model/IPreparedPictureFilterParams";
import { IClient } from "model/entities/Client";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import TPicture, {
  IItemPictureMetaData,
  IReportPictureMetaData,
  isListPicture,
  isReportPicture,
} from "model/entities/Picture";
import IUser from "model/entities/User";
import { IActivity, IWorkflow } from "model/entities/Workflow";
import { DATE_FORMAT_FULL_DATE } from "utils/constants";
import getFullName from "utils/formatting/getFullName";
import { isEmptyValue } from "utils/isEmptyValue";

import { getTagNameFromActivity } from "./components/getSortedPicturesList";
import IPictureFilterParams from "./model/IPictureFilterParams";

export const prepareParamsForFetchingPictures = (
  filterParams: IPictureFilterParams
): IPreparedPictureFilterParams => {
  const preparedFilterParams: IPreparedPictureFilterParams = filterParams[
    FILTER_TAG.DATE
  ]
    ? {
        start_date: filterParams[FILTER_TAG.DATE].startDate,
        end_date: filterParams[FILTER_TAG.DATE].endDate,
      }
    : {
        start_date: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
        end_date: new Date(),
      };

  preparedFilterParams.items_detected = filterParams[FILTER_TAG.ITEMS_DETECTED];
  preparedFilterParams.team_ids = filterParams[FILTER_TAG.TEAMS];
  preparedFilterParams.workflow_ids = filterParams[FILTER_TAG.WORKFLOWS];
  preparedFilterParams.activity_ids = filterParams[FILTER_TAG.ACTIVITIES];
  preparedFilterParams.user_ids = filterParams[FILTER_TAG.USERS];
  preparedFilterParams.list_ids = filterParams[FILTER_TAG.LISTS];
  preparedFilterParams.column_tag = filterParams[FILTER_TAG.ATTRIBUTE_TAG];
  preparedFilterParams.item_ids = _.map(filterParams["item_ids"], "key");
  preparedFilterParams.picture_id = filterParams["picture_id"];
  preparedFilterParams.picture_url = filterParams["picture_url"];
  preparedFilterParams.activity_report_id = filterParams["activity_report_id"];
  preparedFilterParams.workflow_report_id = filterParams["workflow_report_id"];
  preparedFilterParams.picture_tags = filterParams["picture_tags"];

  if (_.has(filterParams, "customer_id") && filterParams["customer_id"]) {
    preparedFilterParams.item_ids = [filterParams["customer_id"]];
  }

  return preparedFilterParams;
};

export const findUserNameFromUsersList = (
  userId: string,
  allUsers: IUser[]
) => {
  const user = allUsers.find((u) => u.id === userId);
  return getFullName({
    first_name: user?.first_name,
    last_name: user?.last_name,
  });
};

export enum PictureActions {
  UploadFileForItem = "UPLOAD_FILE_FOR_ITEM",
  UploadFileForSubmission = "UPLOAD_FILE_FOR_SUBMISSION",
}

interface IGetPictureInformation {
  picture: TPicture;
  allUsers: IUser[];
  allLists: IList[];
  allActivities: IActivity[];
  allWorkflows: IWorkflow[];
  list?: IList;
  client?: IClient;
  item?: IListItem;
}

export const getPictureInformations = (args: IGetPictureInformation) => {
  const {
    picture,
    allLists,
    allActivities,
    allUsers,
    list,
    item: itemFetched,
  } = args;

  const timestamp = moment(picture.timestamp).format(DATE_FORMAT_FULL_DATE);

  const formatLocation = (item: IListItem) => {
    const value = getLocationValue(item, "_location");
    return value.split(",").join(", ");
  };

  let pictureList = list;
  let item = itemFetched;

  if (isReportPicture(picture)) {
    const result: IReportPictureMetaData = {
      ...picture,
      timestamp,
      questionName: getTagNameFromActivity(picture, allActivities),
      userName: findUserNameFromUsersList(picture.user_id, allUsers),
      submissionId: isReportPicture(picture) ? picture.submission_id : "",
      customerId: item?._id,
      customerName: getItemLabel(item),
    };
    const activity = _.find(allActivities, (w) => w.id === picture.workflow_id);
    result.workflowName = activity?.name;
    result["_location"] = item ? formatLocation(item) : undefined;

    _.map(pictureList?.important ?? [], (tag: string) => {
      result[tag] = _.get(item, tag, "");
    });
    result["list_id"] = pictureList?.id;
    result["list_name"] = pictureList?.name;
    result["type"] =
      pictureList?.id === "customer" ? _.get(item, "_type") : undefined;

    if (!_.isEmpty(result.questionName) && picture["activity_id"]) {
      const activity = _.find(
        allActivities,
        (a) => a.id === picture["activity_id"]
      );
      result["activity_name"] = activity?.name;
    }

    if (result["activity_id"] && result["tag"]) {
      const activity = _.find(
        allActivities,
        (a) => a.id === result["activity_id"]
      );
      const question = _.find(
        activity?.questionnaire?.questions,
        (q) => q.tag === result["tag"]
      );
      if (question?.question_text) {
        result["questionName"] = question?.question_text;
      }
    }

    return result;
  }

  if (isListPicture(picture)) {
    pictureList = _.find(allLists, (l) => l.id === picture.list_id);
    item = !item
      ? _.find(pictureList?.items, {
          _id: picture.item_id,
        })
      : item;

    const result: IItemPictureMetaData = {
      ...picture,
      timestamp,
    };

    if (item) result["_location"] = formatLocation(item);

    result.listName = pictureList?.name;
    result.itemName = picture.item_name ?? item?._displayed_name;
    result.userName = findUserNameFromUsersList(picture.user_id, allUsers);

    if (item && pictureList && !isEmptyValue(pictureList.important)) {
      _.map(pictureList.important, (tag: string) => {
        result[tag] = _.get(item, tag, "");
      });
    }

    return result as IItemPictureMetaData;
  }

  return picture;
};

export function getImageData(url: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
export const downloadPictures = (pictures: TPicture[]) => {
  return new Promise((resolve) => {
    const zip = new JSZip();
    zip.folder("pictures");
    // load each picture
    pictures.forEach((picture, i) => {
      zip.file(`pictures/picture_${i + 1}.png`, getImageData(picture.url), {
        binary: true,
      });
    });
    // generate et download the zip
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "pictures.zip");
      resolve("success");
    });
  });
};

export const downloadSinglePicture = (url: string) => {
  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response
        .arrayBuffer()
        .then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "picture.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((e: any) => {
          alert(
            `Error when trying to run response.arrayBuffer(): ${e.message}`
          );
        });
    })
    .catch((_err) => {});
};

export enum TypeSortList {
  Date = "DATE",
  User = "USER",
  Tag = "TAG",
}

export enum TypeSortValue {
  Asc = "ASC",
  Desc = "DESC",
}
