import _ from "lodash";

interface Query {
  [key: string]: any;
}

export const prepareQuery = (query: Query): Query => {
  return _.reduce(
    query,
    (acc, value, key) => {
      if (_.isArray(value)) {
        acc[key] = value.map((v) => {
          if (_.isObject(v) && "key" in v) return v.key;
          return v;
        });
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );
};
