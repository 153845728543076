import React from "react";

import { InfoIcon } from "components/Icon/InfoIcon";
import TLang from "model/application/Lang";
import { isEmptyValue } from "utils/isEmptyValue";

import CustomTableRow from "./CustomTable/CustomTableRow";
import { TABLE_COLUMN_TYPE, TColumnType } from "./model";
import { buildSyntheticRow } from "./utils";

interface SyntheticRowProps {
  columnTypes: TColumnType[];
  rowData: unknown[];
  dbTotalRows?: number;
  hasCheckBox: boolean;
  lang: TLang;
}

const SyntheticRow: React.FC<SyntheticRowProps> = ({
  columnTypes,
  rowData,
  dbTotalRows = 0,
  hasCheckBox,
  lang,
}) => {
  const syntheticRow = buildSyntheticRow(columnTypes, rowData);

  if (dbTotalRows > 5000) {
    columnTypes.forEach((column) => {
      if (column.aggregator && !isEmptyValue(syntheticRow[column.name])) {
        column.type = TABLE_COLUMN_TYPE.CUSTOM;
        syntheticRow[column.name] = (
          <InfoIcon
            tooltip={
              lang.containers.dashboards.subCategories.dashboards
                .createEditModal.inputChart.createEditModal
                .inputTotalRowOver5000.tooltip
            }
          />
        );
      }
    });
  }

  if (Object.values(syntheticRow).filter((v) => v !== "").length > 0) {
    return (
      <CustomTableRow
        rowItem={syntheticRow}
        columnTypes={columnTypes}
        hasCheckBox={hasCheckBox}
        isFooterRow={true}
        lang={lang}
      />
    );
  }

  return null;
};

export default SyntheticRow;
