import moment from "moment";

import IPictureSortBy from "containers/pictures/model/IPictureSortBy";
import { IClient } from "model/entities/Client";
import { IList } from "model/entities/List";
import TPicture, {
  isListPicture,
  isReportPicture,
  PICTURE_ACTIONS,
} from "model/entities/Picture";
import IUser from "model/entities/User";
import { IActivity } from "model/entities/Workflow";
import { DATE_FORMAT } from "utils/constants";

import { findUserNameFromUsersList, TypeSortList } from "../utils";

const getColumnNameFromItemList = (
  picture: TPicture,
  allLists: IList[]
): string => {
  if (!isListPicture(picture)) {
    return "";
  }

  const { tag, list_id } = picture;

  const list = allLists.find((l) => l.id === list_id);

  if (!list || !list.schema) {
    return "";
  }

  const itemTag = list.schema.find((l) => l.column_tag === tag);

  return itemTag ? itemTag.column_name : "";
};

export const getTagNameFromActivity = (
  picture: TPicture,
  allActivities: IActivity[]
): string => {
  if (!isReportPicture(picture)) {
    return "";
  }

  const { tag, workflow_id } = picture;

  const workflow = allActivities.find((l) => l.id === workflow_id);

  if (
    !workflow ||
    !workflow.questionnaire ||
    !workflow.questionnaire.questions
  ) {
    return "";
  }

  const { questions } = workflow.questionnaire;

  let result = "";
  for (let i = 0; i < questions.length; i++) {
    const questionTag = questions.find((q) => q.tag === tag);
    if (questionTag && questionTag.question_text) {
      result = questionTag.question_text;
      break;
    }
  }

  return result;
};

interface IProps {
  allUsers: IUser[];
  allLists: IList[];
  allActivities: IActivity[];
  client?: IClient;
}

export interface ISortingPicture {
  [key: string]: TPicture[];
}

const getFormattedValue = (
  picture: TPicture,
  sortBy: IPictureSortBy,
  props: IProps
): string => {
  const { allUsers, allLists, allActivities } = props;
  switch (sortBy.name) {
    case TypeSortList.Date: {
      return moment(picture.timestamp).format(DATE_FORMAT);
    }
    case TypeSortList.User:
      return findUserNameFromUsersList(picture.user_id, allUsers);

    case TypeSortList.Tag:
      if (picture.action === PICTURE_ACTIONS.UPLOAD_FILE_FOR_ITEM) {
        return getColumnNameFromItemList(picture, allLists);
      } else {
        return getTagNameFromActivity(picture, allActivities);
      }

    default:
      return "";
  }
};

const getSortedPicturesList = (
  pictures: TPicture[],
  sortBy: IPictureSortBy,
  props: IProps
): ISortingPicture => {
  return pictures.reduce<ISortingPicture>((result, picture) => {
    const formattedValue = getFormattedValue(picture, sortBy, props);
    const newResult = { ...result };

    if (!formattedValue) {
      if (!newResult.others) {
        newResult.others = [];
      }
      newResult.others.push(picture);
    } else {
      if (!newResult[formattedValue]) {
        newResult[formattedValue] = [];
      }
      newResult[formattedValue].push(picture);
    }

    return newResult;
  }, {});
};

export default getSortedPicturesList;
