import { useSelector } from "react-redux";

import { getAuthorizedRolesSelector } from "containers/authentication/redux/selector";
import {
  allClientsSelector,
  getLevelViewOptions,
  getSelectedClient,
} from "containers/clients/redux/selectors";
import { teamsComposedSelector } from "containers/teams/redux/selectors";
import { IOption } from "model/application/components";
import { IClient } from "model/entities/Client";
import { IHierarchyLevelOption } from "model/entities/HierarchyDependency";

import getHierarchyLevelsWithColor from "../components/modals/getHierarchyLevelsWithColor";
import { buildOptions } from "../utils";

interface IUseWebUserFormContext {
  userId?: string;
}

const useWebUserFormContext = ({ userId }: IUseWebUserFormContext) => {
  const teams = useSelector(teamsComposedSelector);
  const clientsList = useSelector(allClientsSelector);
  const viewOptions = useSelector(getLevelViewOptions);
  const selectedClient = useSelector(getSelectedClient);
  const authorizedRoles = useSelector(getAuthorizedRolesSelector);

  const levelOptions: IHierarchyLevelOption[] = getLevelOptions(
    viewOptions,
    selectedClient
  );

  const hierarchyDependencies = selectedClient.hierarchy_dependencies;
  const metaHierarchyDependencies = selectedClient.meta_hierarchy_dependencies;

  const userTeams = teams.filter((t) =>
    t.web_users.some((u) => u.key === userId)
  );

  const hierarchyDependenciesWithColor = hierarchyDependencies
    ? getHierarchyLevelsWithColor(
        userTeams,
        levelOptions,
        hierarchyDependencies
      )
    : null;

  const roleOptions = buildOptions(authorizedRoles);
  return {
    teams,
    userTeams,
    clientsList,
    viewOptions,
    roleOptions,
    selectedClient,
    metaHierarchyDependencies,
    hierarchyDependenciesWithColor,
  };
};

const getLevelOptions = (
  viewOptions: IOption[],
  selectedClient: IClient
): IHierarchyLevelOption[] => {
  return viewOptions.filter((o) => {
    if (selectedClient.meta_hierarchy_dependencies) {
      return Object.keys(selectedClient.meta_hierarchy_dependencies).some(
        (m) =>
          selectedClient.meta_hierarchy_dependencies[
            m
          ].level_type_name.toLowerCase() === o.label.toLowerCase()
      );
    }
    return false;
  });
};

export default useWebUserFormContext;
