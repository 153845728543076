import { useState } from "react";

import { Box, Button } from "@material-ui/core";
import { IActionDetails, OBJECT_CATEGORY } from "fieldpro-tools";
import _, { rest } from "lodash";

import { AddIcon } from "components/Buttons/MenuButton";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import { useTranslations } from "hooks";
import { TInputAttributeLang } from "model/application/Lang";

import { getActionName } from "../../../modals/utils";
import useOptionsOfCategory from "./hooks/useOptionsOfCategory";
import InputAccessRightsSpecificRules from "./InputAccessRightsSpecificRules";

interface IInputAccessRights {
  actionName: string;
  defaultStatus: object;
  sectionTitle: string;
  category: OBJECT_CATEGORY;
  actionDetails?: IActionDetails;
  onChange: (value: any, name: string) => void;
  onChangeSpecificRules: (value: any, name: string) => void;
  isMobile?: boolean;
}

const InputAccessRights = (props: IInputAccessRights) => {
  const lang = useTranslations();
  const rootLang =
    lang.containers.clients.subCategories.clients.createEditModal
      .inputAccessRights.createEditModal;
  const {
    actionName,
    sectionTitle,
    defaultStatus,
    category,
    actionDetails,
    onChange,
    onChangeSpecificRules,
    isMobile,
  } = props;

  const [isShowExclusionsPanel, setIsShowExclusionsPanel] = useState(
    !!actionDetails && !!actionDetails.include_type
  );
  const { options } = useOptionsOfCategory(category);
  const shouldShowSpecificRules =
    actionName.includes("LIST") ||
    actionName.includes("DASHBOARD") ||
    (isMobile && actionName.includes("REPORT")) ||
    (isMobile && actionName.includes("ITEM"));
  const onClickToggleSpecificRules = () => {
    setIsShowExclusionsPanel(!isShowExclusionsPanel);
  };

  return (
    <Box>
      <Box width="100%" display="flex">
        <InputMultipleSelect
          key={actionName}
          name={actionName}
          langlabel={
            {
              title: getActionName(actionName, sectionTitle),
            } as TInputAttributeLang
          }
          lang={lang}
          defaultValue={
            _.isArray(defaultStatus[actionName])
              ? defaultStatus[actionName]
              : []
          }
          onChange={onChange}
          options={[
            {
              key: actionName,
              label: getActionName(actionName, sectionTitle),
            },
          ]}
          viewMode="INLINE"
        />
        {_.isArray(defaultStatus[actionName]) && shouldShowSpecificRules && (
          <Button
            {...rest}
            color="secondary"
            startIcon={<AddIcon />}
            onClick={onClickToggleSpecificRules}
            data-testid="button-toggleSpecificRules"
          >
            {rootLang.inputToggleSpecificRules.title}
          </Button>
        )}
      </Box>
      {isShowExclusionsPanel &&
        _.isArray(defaultStatus[actionName]) &&
        shouldShowSpecificRules && (
          <Box width="100%" display="flex" marginTop="15px">
            <InputAccessRightsSpecificRules
              actionName={actionName}
              onChangeSpecificRules={onChangeSpecificRules}
              sectionTitle={sectionTitle}
              options={options}
              actionDetails={actionDetails}
            />
          </Box>
        )}
    </Box>
  );
};

export default InputAccessRights;
