import SatelliteOutlinedIcon from "@mui/icons-material/SatelliteOutlined";

import { GreyBorder } from "assets/colors";
import { useTranslations } from "hooks";

const PictureDetectionAnalysis = () => {
  const lang = useTranslations();
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <SatelliteOutlinedIcon
        style={{
          width: 100,
          height: 100,
          color: GreyBorder,
        }}
      />
      <div
        style={{
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {
          lang.containers.pictures.subCategories.pictures.createEditModal
            .scanResultsTab.customMessage.underAnalysis
        }
      </div>
    </div>
  );
};

export default PictureDetectionAnalysis;
