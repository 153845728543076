import { IWebUser as IBEWebUser } from "fieldpro-tools/dist/src/types/users";

import apiService from "api/apiService";
import { IWebUser } from "model/entities/User";
import { IServerResponse } from "redux/actions/appActions";

import { prepareWebUserForBackend } from "../utils";

export const WEB_USER_ENDPOINT = "/web/user";
export const WEB_USERS_ENDPOINT = "/web/users";

export type TCreateWebUsersFunc = (
  actionName: string,
  users: IWebUser[]
) => Promise<IServerResponse<{ id: string; email: string }[]>>;
export const createWebUsersApiCall: TCreateWebUsersFunc = (
  actionName,
  users
) => {
  return apiService.post(
    `${WEB_USERS_ENDPOINT}`,
    {
      users: users.map((u) => {
        return prepareWebUserForBackend(u);
      }),
    },
    actionName
  );
};

export type TResetWebUsersPasswordFunc = (
  actionName: string,
  userEmail: string
) => Promise<IServerResponse<{ new_password: string }>>;
export const resetWebUsersPasswordApiCall: TResetWebUsersPasswordFunc = (
  actionName,
  email
) => {
  return apiService.post(
    `${WEB_USER_ENDPOINT}/resetpassword`,
    {
      email,
    },
    actionName
  );
};

export type TFetchWebUsersFunc = () => Promise<IServerResponse<IBEWebUser[]>>;
export const fetchWebUsersApiCall: TFetchWebUsersFunc = () => {
  return apiService.get(`${WEB_USERS_ENDPOINT}`);
};

export type TDeleteWebUserFunc = (
  actionName: string,
  userId: string
) => Promise<IServerResponse<{}>>;
export const deleteWebUserApiCall: TDeleteWebUserFunc = (
  actionName,
  userId
) => {
  return apiService.delete(`${WEB_USER_ENDPOINT}/${userId}`, actionName);
};

export type TDeleteWebUsersFunc = (
  actionName: string,
  userIds: string[]
) => Promise<IServerResponse<{}>>;
export const deleteWebUsersApiCall: TDeleteWebUsersFunc = (
  actionName,
  userIds
) => {
  return apiService.delete(
    `${WEB_USERS_ENDPOINT}`,
    {
      ids: userIds,
    },
    actionName
  );
};

export type TEditWebUserFunc = (
  actionName: string,
  id: string,
  first_name: string,
  middle_name: string | undefined,
  last_name: string,
  email: string,
  clients: string[] | undefined,
  role: string,
  picture: string | undefined
) => Promise<IServerResponse<{}>>;
export const updateWebUserApiCall: TEditWebUserFunc = (
  actionName,
  id,
  first_name,
  middle_name,
  last_name,
  email,
  clients,
  role,
  picture
) => {
  const user = prepareWebUserForBackend({
    first_name,
    middle_name,
    last_name,
    email,
    clients,
    role,
    picture,
  } as IWebUser);
  return apiService.patch(`${WEB_USER_ENDPOINT}/${id}`, user, actionName);
};

export type TUpdateWebUsersFunc = (
  actionName: string,
  webUsers: IWebUser[]
) => Promise<IServerResponse<{ success: boolean }>>;
export const updateWebUsersApiCall: TUpdateWebUsersFunc = (
  actionName,
  webUsers: IWebUser[]
) => {
  const body = {
    users: webUsers.map((u) => prepareWebUserForBackend(u)),
  };
  return apiService.patch(`${WEB_USERS_ENDPOINT}`, body, actionName);
};
export type TUploadFileFunc = (
  actionName: string,
  files: any[],
  fileMetas: any
) => Promise<
  IServerResponse<{
    uploaded: boolean;
    files: { filename: string; url: string }[];
  }>
>;
export const uploadFileApiCall: TUploadFileFunc = (
  actionName: string,
  files: any[],
  fileMetas: any
) => {
  return apiService.multipartUpdate(
    `/files`,
    files,
    {
      file_metas: fileMetas,
    },
    actionName
  );
};
