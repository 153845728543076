import _ from "lodash";

import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

export interface IRouteManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (value: boolean, name: string) => void;
  onChangeAccessRole: (values: IOption<string>[]) => void;
}
function RouteManagement({
  client,
  onChange,
  viewMode,
  onChangeAccessRole,
}: IRouteManagement) {
  const lang = useTranslations();

  const {
    mobile_access_right_profiles: accessRightProfiles,
    route_management_access_roles,
  } = client;

  const clientLang = lang.containers.clients.subCategories.clients;

  function handleToggleRouteManagement(value: boolean) {
    onChange(value, "is_route_management_enabled");
  }

  const handleChangeAccessRole = (values: IOption<string>[]) => {
    onChangeAccessRole(values);
  };

  return (
    <>
      <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
        <InputBoolean
          name={"is_route_management_enabled"}
          viewMode={viewMode}
          onChange={handleToggleRouteManagement}
          defaultValue={client.is_route_management_enabled}
          lang={{
            title: clientLang.createEditModal.routeManagement.title,
            tooltip: "",
          }}
          dataTestId="enable_route_management"
        />
      </FormFieldBackground>

      {client.is_route_management_enabled && (
        <InputMultipleSelect
          viewMode={viewMode}
          name={"route_management_access_roles"}
          options={_.map(accessRightProfiles, ({ name }) => ({
            key: name,
            label: _.capitalize(name), //Just to style the label in the chip. the real value is the key
          }))}
          defaultValue={_.map(route_management_access_roles ?? [], (j) => ({
            key: j,
            label: j,
          }))}
          onChange={handleChangeAccessRole}
          multipleSelection={true}
          lang={lang}
          langlabel={
            clientLang.createEditModal.inputClientManagementAccessRoles
          }
          forceDropDownMenu
        />
      )}
    </>
  );
}

export default RouteManagement;
