import { useState } from "react";

import { Box, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { IItemPictureMetaData } from "fieldpro-tools";
import _ from "lodash";

import InputURL from "components/Input/InputUrl";
import MetaDataSection from "components/Layout/MetaDataSection";
import CustomSpinner from "components/Progress/CustomSpinner";
import CustomSnackbar, {
  TSnackbarType,
} from "components/Snackbars/CustomSnackbar";
import useTranslations from "hooks/useTranslations";

import PictureName from "./PictureName";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "auto",
    width: "100%",
  },

  title: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  iconBox: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  detailsBox: {
    display: "grid",
    gridRowGap: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },

  spinnerContainer: {
    width: "100%",
    height: "62vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export interface IPictureDetailsProps {
  loading: boolean;
  pictureMetaData?: {
    customerId?: string;
    customerName?: string;
    item_id?: string;
    timestamp?: string;
    userName?: string;
    user_id?: string;
    activity_report_id?: string;
    workflow_report_id?: string;
    questionName?: string;
    tag?: string;
    url?: string;
  };
}

type TSnackBar = {
  message: string;
  type: TSnackbarType;
} | null;

function PictureMetaData({ loading, pictureMetaData }: IPictureDetailsProps) {
  const classes = useStyles();
  const lang = useTranslations();
  const [snackbar, setSnackbar] = useState<TSnackBar>(null);

  const modalLang =
    lang.containers.pictures.subCategories.pictures.createEditModal;

  const reportsLang = lang.containers.workflows.subCategories.activityReports;

  const customerAttributesLang =
    lang.containers.lists.subCategories.lists.createEditModal.inputAttribute
      .templates.customerAttributes;

  const handleCopy = (message: string, type: TSnackbarType) => {
    setSnackbar?.({ message, type });
  };

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CustomSpinner size={"50px"} />
      </Box>
    );
  }

  const filename = _.last(_.split(pictureMetaData?.url, "/"));

  return (
    <Fade in={!loading}>
      <Box className={classes.root} data-testid="picture-details">
        <PictureName name={filename} onCopy={handleCopy} />

        <Box className={classes.detailsBox}>
          <MetaDataSection
            icon={<PersonOutlineOutlinedIcon />}
            metadata={[
              {
                key: "userName",
                label: reportsLang.columns.userName,
                value: pictureMetaData?.userName,
              },
              {
                key: "user_id",
                label: reportsLang.columns.userId,
                value: pictureMetaData?.user_id,
              },
            ]}
          />

          <MetaDataSection
            icon={<span className="material-symbols-outlined">id_card</span>}
            metadata={[
              {
                key: "customerId",
                label: modalLang.pictureCustomerName.title,
                value:
                  pictureMetaData?.customerName ?? pictureMetaData?.customerId,
              },
              {
                key: "item_id",
                label: modalLang.pictureCustomerId.title,
                value: pictureMetaData?.item_id,
              },
            ]}
          />

          <MetaDataSection
            icon={<LocationOn />}
            metadata={[
              {
                key: "location",
                label: customerAttributesLang.gps,
                value: (pictureMetaData as IItemPictureMetaData | undefined)?.[
                  "_location"
                ],
              },
            ]}
          />

          <MetaDataSection
            icon={<CalendarMonth />}
            metadata={[
              {
                key: "timestamp",
                label: lang.components.filters.date,
                value: pictureMetaData?.timestamp,
              },
            ]}
          />

          <MetaDataSection
            icon={<InventoryOutlinedIcon />}
            metadata={[
              {
                key: "activity_report_id",
                label: reportsLang.assignToMe.columns.activityReportId,
                value: pictureMetaData?.activity_report_id,
              },
            ]}
          />

          <MetaDataSection
            icon={<DescriptionOutlinedIcon />}
            metadata={[
              {
                key: "workflow_report_id",
                label: reportsLang.assignToMe.columns.workflowReportId,
                value: pictureMetaData?.workflow_report_id,
              },
            ]}
          />

          <MetaDataSection
            icon={<HelpOutlineIcon />}
            metadata={[
              {
                key: "questionName",
                label: modalLang.pictureQuestionName.title,
                value: pictureMetaData?.questionName,
              },
              {
                key: "tag",
                label: modalLang.pictureQuestionTag.title,
                value: pictureMetaData?.tag,
              },
            ]}
          />

          <MetaDataSection
            icon={<LinkIcon />}
            metadata={[
              {
                key: "url",
                label: modalLang.url.title,
                value: (
                  <InputURL
                    value={pictureMetaData?.url ?? ""}
                    onCopy={handleCopy}
                  />
                ),
              },
            ]}
          />
        </Box>

        {snackbar !== null && (
          <CustomSnackbar
            message={snackbar.message}
            type={snackbar.type}
            onClose={() => setSnackbar(null)}
          />
        )}
      </Box>
    </Fade>
  );
}

export default PictureMetaData;
