import { Box } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import FormSection from "components/Forms/Form/Section/FormSection";
import FormSubSection from "components/Forms/Form/SubSection/FormSubSection";
import InputImage from "components/Input/InputImage";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { getRolesWithRestrictedAccess } from "containers/authentication/redux/selector";
import useWebUserFormContext from "containers/users/hooks/useWebUserFormContext";
import useFormState, { IValidateFunction } from "hooks/useFormState";
import { useTranslations } from "hooks/useTranslations";
import { OPTIMETRIKS_ROLES } from "model/application/ActionCode";
import { IUserInTable, IWebUser } from "model/entities/User";

import { ICreateEditWebUserAttributes } from "../../model/ICreateEditWebUserAttributes";
import { getErrorMessagesForWebUser } from "../modals/userBuilderUtils";

export interface IViewEditWebUser {
  user?: IWebUser;
  onEditUser: (user: ICreateEditWebUserAttributes) => void;
  onDeleteUser?: (user: IUserInTable) => void;
  onResetPassword?: (webUserId: string) => void;
}

const ViewEditWebUser = ({
  user,
  onEditUser,
  onDeleteUser,
  onResetPassword,
}: IViewEditWebUser) => {
  const lang = useTranslations();
  const langKey = lang.containers.users.subCategories.webUsers.createEditModal;
  const restrictAccess = useSelector(getRolesWithRestrictedAccess);

  const { teams, userTeams, clientsList, roleOptions, selectedClient } =
    useWebUserFormContext({ userId: user?.id });

  const validate: IValidateFunction<ICreateEditWebUserAttributes> = ({
    attributes,
  }) => {
    const additionnalProps = { phoneRegex: selectedClient?.phone_regex };
    return getErrorMessagesForWebUser({
      attributes,
      additionnalProps,
      lang,
      viewMode: "EDIT",
    });
  };

  const {
    attributes,
    errors,
    handleInputChange,
    handleSelectChange,
    handlePictureChange,
    resetChanges,
  } = useFormState<ICreateEditWebUserAttributes>({
    initAttributes: user,
    validate,
  });

  const {
    id,
    email,
    first_name,
    last_name,
    role,
    clients,
    picture,
    linked_teams,
  } = attributes;

  // IDEA: could be refactored so handleConfirmEditWebUser is defined here directly
  const onSave = () => {
    onEditUser(attributes);
  };

  const onDelete = onDeleteUser
    ? () => {
        const userInfo = {
          user_id: attributes.id,
          name: getUserName({ attributes }),
        };

        onDeleteUser(userInfo);
      }
    : undefined;

  const onDiscard = () => {
    resetChanges();
  };

  const shouldMarkError = (field: string) => {
    const hasError = errors?.[field];
    return hasError;
  };

  // Implement a specific behavior when changing the role of a user
  const customEditForRole = (value: any, name: string) => {
    handleInputChange(value, name);

    if (value !== OPTIMETRIKS_ROLES.OPERATION) {
      handleSelectChange(undefined, "clients");
    }
  };

  const defaultClients = clients
    ? clientsList
        .filter((c) => clients.includes(c.id))
        .map((c) => ({
          key: c.id,
          label: c.name,
        }))
    : [];

  return (
    <form>
      <FormSection
        onSave={onSave}
        onDelete={onDelete}
        onDiscard={onDiscard}
        deleteText={
          lang.containers.teams.subCategories.teams.createEditModal.inputWebUser
            .options.delete
        }
        disableSave={!_.isEmpty(errors)}
      >
        {({ viewMode }) => (
          <>
            <FormSubSection>
              <FormFieldBackground viewMode={viewMode}>
                <InputText
                  defaultValue={first_name}
                  error={shouldMarkError("first_name")}
                  name="first_name"
                  lang={langKey.inputFirstName}
                  onChange={handleInputChange}
                  required={true}
                  viewMode={viewMode}
                />
              </FormFieldBackground>

              <InputText
                defaultValue={last_name}
                error={shouldMarkError("last_name")}
                name="last_name"
                lang={langKey.inputLastName}
                onChange={handleInputChange}
                required={true}
                viewMode={viewMode}
              />

              <FormFieldBackground viewMode={viewMode}>
                <InputText
                  defaultValue={email}
                  error={shouldMarkError("email")}
                  name="email"
                  lang={langKey.inputEmail}
                  type={INPUT_TEXT_TYPE.EMAIL}
                  onChange={handleInputChange}
                  required={false}
                  disabled={viewMode === "EDIT" ? !!email : false}
                  viewMode={viewMode}
                />
              </FormFieldBackground>

              <InputSelect
                viewMode={viewMode}
                lang={langKey.inputRole}
                name={"role"}
                value={role}
                onChange={customEditForRole}
                options={roleOptions}
              />

              <FormFieldBackground viewMode={viewMode}>
                {role === OPTIMETRIKS_ROLES.OPERATION ? (
                  <InputMultipleSelect
                    viewMode={viewMode}
                    langlabel={langKey.inputClients}
                    name={"clients"}
                    options={clientsList
                      .filter((c) => c.active)
                      .map((c) => ({
                        key: c.id,
                        label: c.name,
                      }))}
                    multipleSelection={true}
                    onChange={handleSelectChange}
                    defaultValue={defaultClients}
                    lang={lang}
                  />
                ) : null}

                <InputText
                  lang={langKey.inputId}
                  defaultValue={id}
                  name={"id"}
                  onChange={handleInputChange}
                  viewMode={viewMode}
                  disabled={true}
                  replaceSpaces
                  lowercase
                />
              </FormFieldBackground>

              {_.includes(restrictAccess, role) && (
                <InputMultipleSelect
                  viewMode={viewMode}
                  langlabel={langKey.inputTeam}
                  name={"linked_teams"}
                  options={teams
                    .filter((t) => t.active)
                    .map((t) => ({
                      key: t.id,
                      label: t.name,
                    }))}
                  onChange={handleInputChange}
                  multipleSelection={true}
                  defaultValue={
                    linked_teams?.map((t) => ({
                      key: t.key,
                      label: t.label,
                    })) ??
                    userTeams.map((t) => ({
                      key: t.id,
                      label: t.name,
                    }))
                  }
                  lang={lang}
                />
              )}
            </FormSubSection>

            <FormSubSection
              title={langKey.section?.additionnalInfo ?? ""}
              isCollapsible
            >
              <FormFieldBackground viewMode={viewMode}>
                <InputImage
                  lang={langKey.inputProfilePicture}
                  name={"picture"}
                  defaultValue={picture}
                  onChange={handlePictureChange}
                  required={false}
                  viewMode={viewMode}
                />
              </FormFieldBackground>

              {onResetPassword && (
                <Box padding="8px 0px">
                  <CustomButton
                    aria-label="ResetPasswordButton"
                    type={BUTTON_TYPES_OUTSIDE_TABLE.ACTION}
                    onClick={() => {
                      onResetPassword?.(email);
                    }}
                  >
                    {lang.actions.resetPassword}
                  </CustomButton>
                </Box>
              )}
            </FormSubSection>
          </>
        )}
      </FormSection>
    </form>
  );
};

export const getUserName = ({
  attributes,
}: {
  attributes:
    | ICreateEditWebUserAttributes
    | (Object & { first_name?: string; last_name?: string });
}) => `${attributes.first_name || ""} ${attributes.last_name || ""}`.trim();

export default ViewEditWebUser;
