import { IVisitEvent } from "fieldpro-tools";
import moment from "moment";

export function getDefaultDuration({
  start_time,
  end_time,
}: Partial<IVisitEvent>) {
  if (end_time && start_time) {
    const duration = moment(end_time).diff(start_time, "minutes");
    return Math.ceil(duration / 30) * 30;
  }
  return 30;
}
