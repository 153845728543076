import { Dispatch } from "redux";

import { changeProfilePictureSuccessAction } from "containers/authentication/redux/actionCreator";
import { getSignedInUser } from "containers/authentication/redux/selector";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IWebUser } from "model/entities/User";
import IRootState, { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../redux/actions/appActions";
import { getLang, getPrivilege } from "../../../authentication/redux/selector";
import { showNotificationActionCreator } from "../../../notifications/actionCreator";
import * as levels from "../../../notifications/actionLevels";
import * as notificationTypes from "../../../notifications/actionTypes";
import {
  createWebUsersBeginActionCreator,
  createWebUsersFailureActionCreator,
  createWebUsersSuccessActionCreator,
  deleteWebUserBeginActionCreator,
  deleteWebUserFailureActionCreator,
  deleteWebUsersBeginActionCreator,
  deleteWebUsersFailureActionCreator,
  deleteWebUsersSuccessActionCreator,
  deleteWebUserSuccessActionCreator,
  fetchWebUsersBeginActionCreator,
  fetchWebUsersFailureActionCreator,
  fetchWebUsersSuccessActionCreator,
  resetPasswordFailureActionCreator,
  updateWebUserBeginActionCreator,
  updateWebUserFailureActionCreator,
  updateWebUserSuccessActionCreator,
  uploadFileBeginAction,
  uploadFileFailureAction,
  uploadFileSuccessAction,
} from "../actionCreator/webUserActionCreator";
import {
  createWebUsersApiCall,
  deleteWebUserApiCall,
  deleteWebUsersApiCall,
  fetchWebUsersApiCall,
  resetWebUsersPasswordApiCall,
  updateWebUserApiCall,
  updateWebUsersApiCall,
  uploadFileApiCall,
} from "../api/webUserApi";

export interface WebUsersActions {
  createWebUsersAction: ICreateWebUserActionFunc;
  fetchWebUsersAction: IFetchWebUsersActionFunc;
  updateWebUserAction: IUpdateWebUserActionFunc;
  uploadFileWebUserAction: IUploadFileActionFunc;
  deleteWebUserAction: IDeleteWebUserActionFunc;
  deleteWebUsersAction: IDeleteWebUsersActionFunc;
  resetUserPasswordAction: IResetUserPasswordActionFunc;
  updateWebUsersAction: TUpdateWebUsersActionFunc;
}

export type IFetchWebUsersActionFunc = () => IDispatchAndGetState<void>;
export const fetchWebUsersAction: IFetchWebUsersActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const privilege = getPrivilege(getState());
    dispatch(ajaxRequestAction());
    dispatch(fetchWebUsersBeginActionCreator());

    return fetchWebUsersApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(fetchWebUsersSuccessActionCreator(data, privilege));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchWebUsersError",
          error,
          fetchWebUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUpdateWebUserActionFunc = (
  actionName: string,
  userId: string,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  clients: string[],
  role: string,
  picture?: string
) => IDispatchAndGetState<void>;
export const updateWebUserAction: IUpdateWebUserActionFunc = (
  actionName: string,
  userId,
  firstName,
  middleName,
  lastName,
  email,
  clients,
  role,
  picture
) => {
  return (dispatch, getState) => {
    const signedUser = getSignedInUser(getState());
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateWebUserBeginActionCreator());

    return updateWebUserApiCall(
      actionName,
      userId,
      firstName,
      middleName,
      lastName,
      email,
      clients,
      role,
      picture
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        const user = {
          id: userId,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email,
          role,
          picture,
        };
        dispatch(ajaxSuccessAction());
        dispatch(updateWebUserSuccessActionCreator([user]));
        if (signedUser.email === email) {
          dispatch(changeProfilePictureSuccessAction(picture));
        }
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.WEB_USER,
              `${firstName} ${lastName}`
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateWebUserError",
          error,
          updateWebUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type TUpdateWebUsersActionFunc = (
  actionName: string,
  webUsers: IWebUser[]
) => any;

export const updateWebUsersAction: TUpdateWebUsersActionFunc = (
  actionName: string,
  webUsers
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateWebUserBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateWebUsersApiCall(actionName, webUsers)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateWebUserSuccessActionCreator(webUsers));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.WEB_USER,
                webUsers.length,
                true
              )
            )
          );
          resolve("");
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateWebUserError",
            error,
            updateWebUserFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IDeleteWebUserActionFunc = (
  actionName: string,
  userId: string
) => IDispatchAndGetState<void>;
export const deleteWebUserAction: IDeleteWebUserActionFunc = (
  actionName: string,
  userId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWebUserBeginActionCreator());

    return deleteWebUserApiCall(actionName, userId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteWebUserSuccessActionCreator(userId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WEB_USER,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWebUserError",
          error,
          deleteWebUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IDeleteWebUsersActionFunc = (
  actionName: string,
  userIds: string[]
) => IDispatchAndGetState<void>;
export const deleteWebUsersAction: IDeleteWebUsersActionFunc = (
  actionName: string,
  userIds
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWebUsersBeginActionCreator());

    return deleteWebUsersApiCall(actionName, userIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteWebUsersSuccessActionCreator(userIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WEB_USER,
              userIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWebUsersError",
          error,
          deleteWebUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateWebUserActionFunc = (
  actionName: string,
  users: IWebUser[]
) => IDispatchAndGetState<any>;
export const createWebUsersAction: ICreateWebUserActionFunc = (
  actionName,
  users
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the id of the user who is creating this user. this won't be passed in to the given POST request
    // this will instead be passed to the redux store
    // also get the created_at date time

    dispatch(ajaxRequestAction());
    dispatch(createWebUsersBeginActionCreator());

    return new Promise((resolve, reject) => {
      createWebUsersApiCall(actionName, users)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          // create list of users with id
          users = users.map((u) => {
            return {
              ...u,
              id: data.filter((d) => d.email === u.email)[0].id,
            };
          });
          dispatch(createWebUsersSuccessActionCreator(users));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.WEB_USER,
                users.length,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "CreateWebUsersError",
            err,
            createWebUsersFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};

export type IResetUserPasswordActionFunc = (
  actionName: string,
  userEmail: string
) => any;
export const resetUserPasswordAction: IResetUserPasswordActionFunc = (
  actionName: string,
  userEmail
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise((resolve, reject) => {
      resetWebUsersPasswordApiCall(actionName, userEmail)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          resolve({ email: userEmail, password: data.new_password });
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "ResetWebUsersPasswordError",
            err,
            resetPasswordFailureActionCreator,
            currLang
          );
          reject();
        });
    });
  };
};
export type IUploadFileActionFunc = (
  actionName: string,
  files: any[]
) => IDispatchAndGetState<{
  uploaded: boolean;
  files: {
    filename: string;
    url: string;
  }[];
}>;
export const uploadFileWebUserAction: IUploadFileActionFunc = (
  actionName: string,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());
    const fileMetas = { filetype: "WEB_USER" };

    return new Promise(function (resolve, reject) {
      uploadFileApiCall(actionName, files, fileMetas)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.WEB_USER,
                ""
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          reject("Something went wrong");
        });
    });
  };
};
