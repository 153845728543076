import { aggregateValues } from "utils/utils";

import {
  TABLE_AGGREGATOR,
  TABLE_COLUMN_TYPE,
  TColumnType,
  TDataFormatted,
} from "./model";

export const formatColumnTypesForMatrix = (
  data: TDataFormatted[]
): TColumnType[] => {
  const res: TColumnType[] = [];
  if (data[0]) {
    Object.keys(data[0]).forEach((att) => {
      if (att.startsWith("_")) {
        res.push({
          label: "",
          name: att,
          type: TABLE_COLUMN_TYPE.TEXT,
        });
      } else {
        res.push({
          label: "",
          name: att,
          type: TABLE_COLUMN_TYPE.NUMBER,
        });
      }
    });
  }
  return res;
};

export const buildSyntheticRow = (
  columnTypes: TColumnType[],
  rowData: any[]
) => {
  const syntheticRow: { [something: string]: number | string | JSX.Element } =
    {};
  columnTypes.forEach((t) => {
    let aggregation: any = "";
    if (
      t.type === TABLE_COLUMN_TYPE.NUMBER &&
      t.aggregator &&
      t.aggregator !== TABLE_AGGREGATOR.NONE
    ) {
      aggregation = aggregateValues(
        rowData.map((r) => r[t.name]),
        t.aggregator
      );
    }
    if (!isNaN(aggregation) && aggregation !== "") {
      syntheticRow[t.name] = Math.round(aggregation * 100) / 100;
    } else syntheticRow[t.name] = aggregation;
  });
  return syntheticRow;
};

export const getCleanRowData = (rowData: any[]) => {
  return rowData.sort((a, b) => {
    let bCleaned, aCleaned;
    if (rowData.length != 0 && rowData[0].hasOwnProperty("_displayed_name")) {
      bCleaned = b["_displayed_name"]
        ? b["_displayed_name"].toLowerCase()
        : b["_displayed_name"] != null
        ? b["_displayed_name"].toString()
        : "a";

      aCleaned = a["_displayed_name"]
        ? a["_displayed_name"].toLowerCase()
        : a["_displayed_name"] != null
        ? a["_displayed_name"].toString()
        : "a";
    } else {
      bCleaned = b["name"]
        ? b["name"].toLowerCase()
        : b["name"] != null
        ? b["name"].toString()
        : "a";

      aCleaned = a["name"]
        ? a["name"].toLowerCase()
        : a["name"] != null
        ? a["name"].toString()
        : "a";
    }

    return aCleaned < bCleaned ? -1 : 1;
  });
};

export const getScrollPosition = (classes: string) => {
  const element = document.querySelector(classes);

  if (element) {
    const x = element.scrollLeft;
    const y = element.scrollTop;
    return {
      x,
      y,
    };
  }

  return {
    x: 0,
    y: 0,
  };
};
