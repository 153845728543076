import { CalendarEventRepetitionType } from "fieldpro-tools";
import _ from "lodash";

import { formatString } from "lang/utils";
import TLang from "model/application/Lang";
import { IEventfromFile } from "model/entities/Calender";
import { isValidDateString } from "utils/dateUtils";

export function checkEventForErrors(
  newEvent: IEventfromFile,
  line: number,
  lang: TLang,
  isDayOnly: boolean
) {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit.customErrorMessages;

  const errors = {
    EMPTY_REQUIRED_KEYS: () =>
      checkForEmptyRequiredKeys(newEvent, inputVisitLang, isDayOnly),
    VISIT_CUSTOMER_ID_REQUIRED: () =>
      checkCustomerIdRequired(newEvent.customer_id || "", inputVisitLang),
    START_TIME_REQUIRED: () =>
      checkStartTimeRequired(newEvent, inputVisitLang, isDayOnly),
    END_TIME_REQUIRED: () =>
      checkEndTimeRequired(newEvent, inputVisitLang, isDayOnly),

    REPETITION_TYPE: () =>
      newEvent.repetition_type
        ? isRepetitionTypeValid(
            newEvent.repetition_type,
            inputVisitLang.repetitionTypeError
          )
        : null,
  };

  for (const [, check] of Object.entries(errors)) {
    const error = check();
    if (error) return formatString(inputVisitLang.errorInLine, [line, error]);
  }

  return null;
}

/* ----------------------------- ERROR FUNCTIONS ---------------------------- */
export function checkCustomerIdRequired(
  customer_id: string,
  inputVisitLang: any
): string | null {
  return _.isEmpty(customer_id) ? inputVisitLang.customerIdRequired : null;
}

export function checkStartTimeRequired(
  newEvent: IEventfromFile,
  inputVisitLang: any,
  isDayOnly?: boolean
): string | null {
  return !isValidDateString(newEvent.start_time)
    ? isDayOnly
      ? inputVisitLang.dateRequired
      : inputVisitLang.startTimeRequired
    : null;
}

function checkEndTimeRequired(
  newEvent: IEventfromFile,
  inputVisitLang: any,
  isDayOnly?: boolean
): string | null {
  return !isValidDateString(newEvent.end_time)
    ? isDayOnly
      ? inputVisitLang.dateRequired
      : inputVisitLang.endTimeRequired
    : null;
}

function checkForEmptyRequiredKeys(
  newEvent: IEventfromFile,
  inputVisitLang: any,
  isDayOnly: boolean
): string | null {
  const requiredKeys: Array<keyof any> = ["start_time", "assigned_to"];
  if (!isDayOnly) {
    requiredKeys.push("end_time");
  }
  const emptyKeys = _.filter(requiredKeys, (key) => {
    return newEvent[key] === "" || newEvent[key] === undefined;
  });

  if (!_.isEmpty(emptyKeys)) {
    return formatString(inputVisitLang.requiredField, [
      _.join(emptyKeys, ", "),
    ]);
  }
  return null;
}

export function isRepetitionTypeValid(
  repetition_type: CalendarEventRepetitionType,
  errorLabel: string
): string | null {
  return Object.values(CalendarEventRepetitionType).includes(repetition_type)
    ? null
    : errorLabel;
}
