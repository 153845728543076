import { ACTION_CODE, IAccessRightProfile } from "fieldpro-tools";

export { LOGOUT_REQUEST_SUCCESS } from "../../containers/authentication/redux/actionTypes";
// action type that specifies this is the first app login
export const APP_FIRST_LOGIN = "APP_LOGIN_SUCCESS";
export const APP_ADD_NOTIFICATION = "APP_ADD_NOTIFICATION";
export const APP_REMOVE_NOTIFICATION = "APP_REMOVE_NOTIFICATION";
export const APP_CLEAN_NOTIFICATION = "APP_CLEAN_NOTIFICATION";
export const OPEN_WARNING_MODAL = "OPEN_WARNING_MODAL";
export const CLOSE_WARNING_MODAL = "CLOSE_WARNING_MODAL";

export type TAvailableActions = Partial<{
  [key in keyof typeof ACTION_CODE]: boolean;
}>;

export type TAccessRightProfile = IAccessRightProfile;
