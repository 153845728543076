import BlockIcon from "@mui/icons-material/Block";

import { Green } from "assets/colors";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import { IKPI } from "model/entities/Dashboard";

interface IQueryDisabledChart {
  chart: IKPI<any>;
}
const QueryDisabledChart = ({ chart }: IQueryDisabledChart) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.dashboards.subCategories.dashboards.chartDisabled;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        gap: "10px",
      }}
    >
      <BlockIcon
        style={{
          fontSize: 30,
          color: Green,
        }}
      />
      <span style={{ color: Green }}>{langKey.title}</span>
      <span>
        {formatString(langKey.description, [chart.type, chart.title])}
      </span>
    </div>
  );
};

export default QueryDisabledChart;
