import { connectRouter } from "connected-react-router";
import _ from "lodash";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import apiService from "api/apiService";
import auth from "containers/authentication/redux/reducer";
import calendar_events from "containers/calendar/redux/reducer";
import { CHANGE_SELECTED_CLIENT } from "containers/clients/redux/actionTypes";
import { EMPTY_INIT_CLIENT } from "containers/clients/redux/initialState";
import clients from "containers/clients/redux/reducer";
import customers from "containers/customers/redux/reducer";
import dashboards from "containers/dashboards/redux/reducer";
import documents from "containers/documents/redux/reducer";
import environments from "containers/environments/redux/reducer";
import discounts from "containers/erp/redux/discounts/reducer";
import erpOrders from "containers/erp/redux/orders/reducer";
import prices from "containers/erp/redux/prices/reducer";
import stock from "containers/erp/redux/stock/reducer";
import folders from "containers/folders/redux/reducer";
import lists from "containers/lists/redux/reducer";
import notify from "containers/notifications/reducer";
import orders from "containers/panorama/redux/reducer.ts";
import teams from "containers/teams/redux/reducer";
import territories from "containers/territories/redux/reducer";
import users from "containers/users/redux/reducer/reducer";
import meta_expressions from "containers/workflows/redux/meta_expressions/reducer";
import workflows from "containers/workflows/redux/reducer";
import triggers from "containers/workflows/redux/triggers/reducer";
import webhooks from "containers/workflows/redux/webhooks/reducer";
import jobs from "containers/workflows/subcategories/jobs/redux/reducer";
import fullInitState from "redux/initialState";
import { history } from "redux/store/history";
import { persistConfig } from "redux/store/persistConfig";

import ajax from "./ajaxReducer";
import app from "./appReducer";

/**
 * Combines all reducers for use in the application
 * Uses short hand property names from ES6
 *  */
const rootReducer = (history) => {
  return (state, action) => {
    if (action?.type === "persist/REHYDRATE") {
      /**
       * IMPORTANT: when opening a new tab, the redux store is rehydrated
       * but we need to also re-initialise the apiService
       */
      const { auth, clients } = action.payload || {};
      const clientId = clients?.selectedClient?.id || "";
      const token = auth?.smalaToken || "";

      apiService.setClientId(clientId);
      apiService.setToken(token);
    }

    if (action?.type === CHANGE_SELECTED_CLIENT) {
      /**
       * This action does 2 things:
       * - update selectedClient with the provided object
       * - reset the redux store, but keep minimal state like notifications (inside app),
       *   credentials (auth), current page (router), and redux-persist info (_persist)
       */
      const { selectedClient } = action;

      // TODO: this is hack related to the refacto of the clients list fetching.
      // Now that we receive only a "light" client, when we switch clients from the list we can have some crashes
      // If all the fields of the client are not initialized according IClient.
      // So while waiting for the loading of the full client, we fill it with reasonable defaults...
      const lightClientWithSafeDefaults = {
        ...EMPTY_INIT_CLIENT,
        ...selectedClient,
      };

      // IMPORTANT: synchronise apiService with store
      apiService.setClientId(selectedClient.id);

      return {
        ...fullInitState,
        ..._.pick(state, "app", "auth", "router", "_persist"),
        clients: {
          ...state.clients,
          selectedClient: lightClientWithSafeDefaults,
        },
      };
    }

    return createRootReducer(history)(state, action);
  };
};

const createRootReducer = (history) =>
  combineReducers({
    webhooks,
    triggers,
    app,
    auth,
    notify,
    ajax,
    meta_expressions,
    users,
    customers,
    clients,
    folders,
    teams,
    calendar_events,
    dashboards,
    workflows,
    lists,
    territories,
    documents,
    environments,
    jobs,
    orders,
    erpOrders,
    prices,
    stock,
    discounts,
    routing: routerReducer,
    // Do not change "router" key
    // https://www.npmjs.com/package/connected-react-router#usage
    router: connectRouter(history),
  });

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default persistedReducer;
