import {
  CalendarEventRepetitionType,
  CalendarEventType,
  IDSource,
} from "fieldpro-tools";
import _ from "lodash";
import Moment from "moment";

import { ILine } from "components/Input/LegacyInput/UploadFileInput/UploadFileInput";
import TLang from "model/application/Lang";
import { IEventfromFile, IVisitEvent } from "model/entities/Calender";

import { checkEventForErrors } from "./checkEventForErrors";
const keys: Array<keyof IEventfromFile> = [
  "description",
  "date",
  "start_date",
  "start_time",
  "end_date",
  "end_time",
  "assigned_to",
  "customer_id",
  "repetition_type",
];
export function getCsvBodyTemplate(isDayOnly: boolean) {
  const acceptedDateFormat =
    "format(DD/MM/YYYY; D/M/YYYY; YYYY-MM-DD; YYYY-M-D;)";
  const acceptedTimeFormat =
    "format(HH:mm:ss; HH:mm; hh:mm A; hh:mm:ss; A H:mm)";
  const mockVisit = {
    description: "Description",
    ...(isDayOnly
      ? {
          date: acceptedDateFormat,
        }
      : {
          start_date: acceptedDateFormat,
          start_time: acceptedTimeFormat,
          end_date: acceptedDateFormat,
          end_time: acceptedTimeFormat,
        }),
    assigned_to: IDSource(),
    customer_id: IDSource(),
    repetition_type: CalendarEventRepetitionType.DAILY,
  };
  const newKeys = [...keys];

  if (isDayOnly) {
    _.pull(newKeys, "start_date", "start_time", "end_date", "end_time");
  } else {
    _.pull(newKeys, "date");
  }
  const values = _.map(_.values(mockVisit), _.toString);
  return _.join(newKeys, ",").concat("\n").concat(_.join(values, ","));
}

export const formatTime = (time: string, moment: typeof Moment) => {
  const timeMoment = moment(time, [
    "HH:mm:ss",
    "HH:mm",
    "hh:mm A",
    "hh:mm:ss A",
    "H:mm",
  ]);
  return timeMoment.format("HH:mm:ss");
};
const formatDate = (date: string, moment: typeof Moment) => {
  const dateMoment = moment(date, [
    "DD/MM/YYYY",
    " D/M/YYYY",
    "YYYY-MM-DD",
    "YYYY-M-D",
  ]);
  return dateMoment.format("YYYY-MM-DD");
};

export const mergeDateAndTime = (
  date: string,
  time: string,
  moment: typeof Moment
) => {
  const formattedDate = formatDate(date, moment);
  const formattedTime = formatTime(time, moment);
  const finalDateTime = moment(
    `${formattedDate} ${formattedTime}`,
    "YYYY-MM-DD HH:mm:ss"
  );

  return finalDateTime.toDate();
};

export function parseEventsCSV(
  lines: ILine[],
  lang: TLang,
  isDayOnly: boolean,
  moment: typeof Moment
) {
  const errors: string[] = [];
  const data: IVisitEvent[] = [];
  const startDayTime = moment().startOf("day").format("HH:mm:ss");

  const endDayTime = moment().endOf("day").format("HH:mm:ss");

  //Parse and add events
  _.forEach(_.tail(lines), (line, index) => {
    const event = _.zipObject(lines[0], line) as any as IEventfromFile;

    event.repetition_type =
      event.repetition_type?.toLowerCase() as CalendarEventRepetitionType;

    event.start_time = event.date
      ? mergeDateAndTime(event.date as string, startDayTime as string, moment)
      : event.start_date
      ? mergeDateAndTime(event.start_date, event.start_time as string, moment)
      : event.start_time;
    event.end_time = event.date
      ? mergeDateAndTime(event.date as string, endDayTime as string, moment)
      : event.end_date
      ? mergeDateAndTime(event.end_date, event.end_time as string, moment)
      : event.end_time;
    if (isDayOnly) {
      event.full_day = true;

      delete event.date;
    }

    delete event.start_date;
    delete event.end_date;

    const error = checkEventForErrors(event, index + 2, lang, isDayOnly);

    if (error) {
      errors.push(error);
      return;
    }

    event.title = "";
    event.type = CalendarEventType.VISIT;

    data.push(event as IVisitEvent);
  });
  if (!_.isEmpty(errors)) {
    return { err: "\n" + errors.join("\n") };
  }
  return _.compact(data);
}
