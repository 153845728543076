import { makeStyles } from "@material-ui/core";
import _ from "lodash";

import { White } from "assets/colors";
import DefaultChip from "components/Chip/DefaultChip";
import { DeleteIcon } from "components/Icon";
import { IOption } from "model/application/components";

import { IInputMultipleSelectPropsBase } from "../../InputMultipleSelect/InputMultipleSelect";
import styles from "../../styles";

const useStyles = makeStyles(styles as any);
interface IChipContainer
  extends Pick<IInputMultipleSelectPropsBase, "langlabel"> {
  selectedOptions: IOption[];
  onRemoveOption: (options: IOption) => void;
}
const ChipContainer: React.FC<IChipContainer> = ({
  langlabel,
  selectedOptions,
  onRemoveOption,
}) => {
  const classes = useStyles();
  const { placeholder } = langlabel;
  return (
    <div className={classes.chipContainer}>
      {placeholder && selectedOptions.length === 0 ? (
        <span style={{ paddingLeft: "8px" }}>{placeholder}</span>
      ) : null}
      {_.map(selectedOptions, (opt) => {
        return (
          <DefaultChip
            style={{
              maxWidth: "200px",
            }}
            title={opt.label}
            highlight={opt.highlight}
            disabled={opt.disabled}
            key={opt.key}
            label={opt.label}
            tabIndex={-2}
            onDelete={() => {
              onRemoveOption(opt);
            }}
            deleteIcon={
              <DeleteIcon
                onClick={(e: any) => e?.stopPropagation()}
                data-testid={`delete-${opt.key}`}
                style={{
                  marginLeft: "-6px",
                  marginRight: "2px",
                  color: opt.highlight ? White : undefined,
                }}
              />
            }
          />
        );
      })}
    </div>
  );
};
export default ChipContainer;
