import {
  DependencyList,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import _ from "lodash";

import { CLEAR_KEYWORD } from "api/constants";
import { LiteralUnion } from "utils/types";
import { generateRandomId } from "utils/utils";

/**
 * Generic use form state hooks
 * Used to manage the state of a form, and update its values.
 */

interface IUseFormState<TAttributes = {}> {
  initAttributes?: Partial<TAttributes>;
  validate?: IValidateFunction<TAttributes>;
  validateWarnings?: IValidateFunction<TAttributes>;
  validateDependencies?: DependencyList;
  onAttributeChange?: (attributes: keyof TAttributes, attrName: string) => void;
}

export interface IFormStateReducerAction {
  name: string;
  value: any;
}

export type TValue = IFormStateReducerAction["value"];
export type TName = IFormStateReducerAction["name"];

export interface IFileInputData {
  tag: string; // key of the Question, List attribute, ... (i.e form input name)
  file: any; // actual file data
  fileName: string;
}

interface IResetChangesArgs<TAttributes> {
  attributes?: TAttributes;
}

export type TResetChanges<TAttributes> = (
  args?: IResetChangesArgs<TAttributes>
) => void;

// Just doing  keyof TAttributes causes too many typescript errors
type TAttrName<TAttributes> = LiteralUnion<
  keyof TAttributes extends string ? keyof TAttributes : string
>;

// This type would enforce the value to be the same type as the attribute
// based on the name selected, using it though causes errors in the useFormState
// TODO : if possible try using this in the handle functions
// type EditObjectFunction<T> = <K extends keyof T>(value: T[K], name: K) => void;

export interface IFormState<TAttributes> {
  attributes: TAttributes;
  warnings: any;
  errors: any;
  filesToUpload: IFileInputData[];
  handleInputChange: (attrValue: any, attrName: TAttrName<TAttributes>) => void;
  handleSelectChange: (
    attrValue: any,
    attrName: TAttrName<TAttributes>
  ) => void;
  handleMultipleSelectChange: (
    attrValue: any[],
    attrName: TAttrName<TAttributes>
  ) => void;
  handlePictureChange: (file: any, name: TAttrName<TAttributes>) => void;
  handleFileChange: (file: any, name: TAttrName<TAttributes>) => void;
  handleGPSChange: (value: any, name: TAttrName<TAttributes>) => void;
  handleDateChange: (value: string, name: TAttrName<TAttributes>) => void;
  resetChanges: TResetChanges<TAttributes>;
  onSubmit: (callback: Function, ...args: any) => void;
}

export interface IValidateFunction<TAttributes> {
  ({ attributes }: { attributes: TAttributes }): any; // TODO: type "any"
}

/**
 * @arg validate validation function to be run when form state changes
 * @arg validateDependencies a list of dependencies for the validation, similar to what you pass to useEffect. No need to pass attributes as it is done by default
 */
const useFormState = <TAttributes>({
  initAttributes,
  validate,
  validateWarnings,
  validateDependencies,
  onAttributeChange,
}: IUseFormState<TAttributes>): IFormState<TAttributes> => {
  const formStateReducer = (state: any, action: IFormStateReducerAction) => {
    const { name, value } = action;
    // NOTE: special case to reset to inital state or any other state
    if (name === "_reset") {
      return value;
    }
    if (onAttributeChange) {
      onAttributeChange(value, name);
    }
    return {
      ...state,
      [name]: value,
    };
  };

  const [attributes, dispatch] = useReducer(
    formStateReducer,
    initAttributes || {}
  );
  const [warnings, setWarnings] = useState<string | null>();
  const [errors, setErrors] = useState<any>();

  const [filesToUpload, setFilesToUpload] = useState<IFileInputData[]>([]);
  // const isFirstRender = useIsFirstRender();

  useEffect(() => {
    // should validate onChange, not on first render
    if (/* !isFirstRender && */ validate) {
      const errors = validate({ attributes });
      if (errors) {
        setErrors(errors);
      }
    }

    if (validateWarnings) {
      const warnings = validateWarnings({ attributes });
      if (warnings) {
        setWarnings(warnings);
      }
    }
    // TO BE DONE: refactor and remove eslint-disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes, ...(validateDependencies ?? [])]);

  /**
   * Generic function to change the value of a simple input
   * @param value The new value to pass
   * @param name The name of the property
   * @returns The object dispatched
   */
  // NOTE: might need to wrap with useCallback or useMemo someday ?
  // To avoid return a new handler to children on every render.
  // However this has no effect if the parent Form's state changes,
  // and the child isn't a "pure" component (see React.memo() or React.PureComponent)
  const handleInputChange = (
    value: TValue,
    name: TName,
    formatValue?: ({ name, value }: IFormStateReducerAction) => TValue,
    formatName?: ({ name, value }: IFormStateReducerAction) => TName
  ) => {
    const newName = formatName ? formatName({ name, value }) : name;
    const newValue = formatValue ? formatValue({ name, value }) : value;
    const update = { name: newName, value: newValue };
    dispatch(update);
    return update;
  };

  const handlePictureChange = (value: any, name: string) => {
    return handleInputChange(value, name, formatPictureValue);
  };

  const handleFileChange = (value: any, name: string, isUrl?: boolean) => {
    if (isUrl) {
      return handleInputChange(value || CLEAR_KEYWORD, name);
    }

    const fileName = getFileName(value);
    const newFile: IFileInputData = {
      tag: name,
      file: value,
      fileName,
    };

    setFilesToUpload((oldFiles) => {
      return [...oldFiles, newFile];
    });

    return handleInputChange(fileName, name);
  };

  /**
   * Generic function for changing single / multiple choice input;
   * @param value can be a single option or mutiple options
   * @param name the name of the property
   * @returns The object dispatched
   */
  const handleSelectChange = (value: any, name: string) => {
    return handleInputChange(value, name, formatSelectValue);
  };

  const handleMultipleSelectChange = (values: any[], name: string) => {
    return handleInputChange(values, name, formatMultipleSelectValue);
  };

  const handleGPSChange = (value: any, name: string) => {
    return handleInputChange(value, name, formatGPSValue);
  };

  const handleDateChange = (value: string, name: string) => {
    return handleInputChange(new Date(value).toISOString(), name);
  };

  const resetChanges: TResetChanges<TAttributes> = useCallback(
    (args) => {
      const { attributes } = args || {};
      setFilesToUpload([]);
      return dispatch({
        name: "_reset",
        value: attributes || initAttributes || {},
      });
    },
    [initAttributes]
  );
  const onSubmit = (callback: Function, ...args: any) => {
    if (validate) {
      const errors = validate({ attributes });
      if (errors) {
        setErrors(errors);
      } else {
        setErrors({});
      }
      if (!_.isEmpty(errors)) {
        return;
      }
    }

    if (callback) {
      callback(...args);
    }
  };

  return {
    attributes,
    filesToUpload,
    warnings,
    errors,
    handleInputChange,
    handlePictureChange,
    handleFileChange,
    handleSelectChange,
    handleMultipleSelectChange,
    handleGPSChange,
    handleDateChange,
    resetChanges,
    onSubmit,
  };
};

// NOTE: could we merge formatFileValue and formatPictureValue ?
const formatPictureValue = ({ value }: IFormStateReducerAction) => {
  const fileName = getFileName(value);
  const fileValue = value ? { value, fileName } : null;
  return fileValue;
};

const getFileName = (fileValue: any) => {
  return fileValue
    ? getRandomFileNameWithExtension(fileValue?.name)
    : CLEAR_KEYWORD;
};

export const getRandomFileNameWithExtension = (originalFileName: string) => {
  const randomId = generateRandomId();
  const extension = (originalFileName ?? "").split(".").pop();
  if (!extension) {
    return randomId;
  }
  return `${randomId}.${extension}`;
};

const formatSelectValue = ({ value }: IFormStateReducerAction) => {
  let newValue: any = undefined;
  if (value) {
    if (_.isString(value)) {
      // case of single select
      newValue = value;
    } else {
      // case of multiple select
      newValue = value.map((v: any) => v.key);
    }
  }
  return newValue;
};

const formatMultipleSelectValue = ({ value }: IFormStateReducerAction) => {
  return (value as any[]).map((e) => e.key);
};

interface IGPSValue {
  lat?: number | string;
  lng?: number | string;
  acc?: number | string;
}

interface IFormatGPSValue {
  value: IGPSValue;
}

const formatGPSValue = ({ value }: IFormatGPSValue) => {
  // no value is registered for the report, we remove the attribute from the report.
  if (value.lat === "" && value.lng === "" && value.acc === "") {
    return null;
  }
  return value;
};

export default useFormState;
