import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";

import { TEventPlaceHolder } from "../Board";

interface IComputeEventsMapProps {
  dates: string[];
  events: TCalendarEvent[];
  eventPlaceHolders: TEventPlaceHolder[];
  momentObject: typeof moment;
}
export function computeEventsMap({
  dates,
  eventPlaceHolders,
  events,
  momentObject,
}: IComputeEventsMapProps) {
  const sizes: number[] = [];
  const eventsArray: Array<Array<TCalendarEvent>> = [];
  const placeHoldersArray: Array<Array<TEventPlaceHolder>> = [];

  _.forEach(dates, (date) => {
    const matchingEvents = events.filter((event) => {
      const isSameDay = momentObject(event.start_time)
        .startOf("day")
        .isSame(momentObject(date).startOf("day"));

      return isSameDay;
    });

    const matchingPlaceHolders = eventPlaceHolders?.filter((event) => {
      const isSameDay = momentObject(event.start_time)
        .startOf("day")
        .isSame(momentObject(date).startOf("day"));

      return isSameDay;
    });

    placeHoldersArray.push(matchingPlaceHolders ?? []);
    eventsArray.push(matchingEvents);
    sizes.push(matchingEvents.length);
  });

  return { eventsArray, placeHoldersArray, sizes };
}
