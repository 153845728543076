import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import * as htmlToImage from "html-to-image";
import { TLogger } from "log/FieldProLogger";

import {
  DATA_PDF_PRINTABLE_ATTRIBUTE,
  PDF_PRINTABLE_ELEMENT,
} from "components/Dashboard/Chart/Chart";
import {
  buildDashboardPdf,
  IPdfChartData,
  IPdfClient,
} from "components/Dashboard/Pdf/PdfDashboard";
import { IOption } from "model/application/components";

import { IDashboard, IKPI, KPI_TYPE } from "../../../model/entities/Dashboard";
const saveSvgAsPng = require("save-svg-as-png");

export const isFilterInDashboard = (
  filtername:
    | "workflows"
    | "users"
    | "teams"
    | "date"
    | "attr"
    | "param1"
    | "custom",
  dashboard: IDashboard
): boolean => {
  return dashboard.kpis.find(
    (k) =>
      (k.query && k.query.includes(`${filtername}_filter`)) ||
      (k.query && k.query.includes(`${filtername}$`)) ||
      (k.column_pivot && k.column_pivot.includes(`$${filtername}$`)) ||
      (k.row_pivot && k.row_pivot.includes(`$${filtername}$`)) ||
      (k.sub_queries &&
        k.sub_queries.find((subQ) =>
          subQ.query.includes(`${filtername}_filter`)
        ))
  )
    ? true
    : false;
};
export const isListAttrInDashboardFilter = (
  column_tag: string,
  dashboard: IDashboard
): boolean => {
  const regexPattern = new RegExp(
    `\\$attr_filter:([a-zA-Z0-9]+\\.?)?${escapeRegExp(column_tag)}\\$`
  );

  return dashboard.kpis.find(
    (k) =>
      (k.query && new RegExp(regexPattern).test(k.query)) ||
      (k.column_pivot && new RegExp(regexPattern).test(k.column_pivot)) ||
      (k.row_pivot && new RegExp(regexPattern).test(k.row_pivot)) ||
      (k.sub_queries &&
        k.sub_queries.find((subQ) => new RegExp(regexPattern).test(subQ.query)))
  )
    ? true
    : false;
};
export const isTagPresentInMultipleLists = (
  column_tag: string,
  dashboard: IDashboard
) => {
  const regexPattern = new RegExp(
    `\\$attr_filter:(?:(?!\\$)[a-zA-Z0-9_]+\\.)?${escapeRegExp(column_tag)}\\$`,
    "g"
  );

  let occurrences: string[] = [];

  dashboard.kpis.forEach((k) => {
    if (k.query && regexPattern.test(k.query)) {
      const matches = k.query.match(regexPattern);
      if (matches) occurrences.push(...matches);
    }
    if (k.column_pivot && regexPattern.test(k.column_pivot)) {
      const matches = k.column_pivot.match(regexPattern);
      if (matches) occurrences.push(...matches);
    }
    if (k.row_pivot && regexPattern.test(k.row_pivot)) {
      const matches = k.row_pivot.match(regexPattern);
      if (matches) occurrences.push(...matches);
    }
    if (
      k.sub_queries &&
      k.sub_queries.length > 0 &&
      Array.isArray(k.sub_queries)
    ) {
      k.sub_queries.forEach((subQ) => {
        if (subQ.query && regexPattern.test(subQ.query)) {
          const matches = subQ.query.match(regexPattern);
          if (matches) occurrences.push(...matches);
        }
      });
    }
  });
  occurrences = [...new Set(occurrences)];
  return occurrences.length > 1;
};
function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
}

export const isFilterInKpi = (
  filtername: "workflows" | "activities" | "users" | "teams" | "date",
  k: IKPI
): boolean => {
  return (k.query && k.query.includes(`${filtername}_filter`)) ||
    (k.column_pivot && k.column_pivot.includes(`$${filtername}$`)) ||
    (k.row_pivot && k.row_pivot.includes(`$${filtername}$`)) ||
    (k.sub_queries &&
      k.sub_queries.find((subQ) => subQ.query.includes(`${filtername}_filter`)))
    ? true
    : false;
};

export const getPdfData = async (
  dashboardTitle: string,
  client: IPdfClient | undefined,
  startDate: number | Date,
  endDate: number | Date,
  teamFilter: IOption[] | undefined,
  userFilter: IOption[] | undefined,
  logger: TLogger
) => {
  const fileName = dashboardTitle;
  const data: IPdfChartData[] = [];
  const elements: NodeListOf<Element> = document.querySelectorAll(
    `span[${DATA_PDF_PRINTABLE_ATTRIBUTE}type]`
  );

  const parser = new DOMParser();
  let src;
  for (const element of elements) {
    const attrType = element.getAttribute(
      `${DATA_PDF_PRINTABLE_ATTRIBUTE}type`
    );
    const type = attrType ? KPI_TYPE[attrType] : undefined;
    const attrTitle = element.getAttribute(
      `${DATA_PDF_PRINTABLE_ATTRIBUTE}title`
    );
    const chartTitle = attrTitle ? attrTitle : "";
    const legendElement = element.querySelector(
      `div[${PDF_PRINTABLE_ELEMENT}]`
    );
    const legendSrc = legendElement
      ? await htmlToImage.toPng(element.childNodes[0] as HTMLElement)
      : "";
    src = undefined;
    let additionalData: any;
    switch (type) {
      case KPI_TYPE.SCORECARD:
      case KPI_TYPE.GAUGE:
      case KPI_TYPE.MATRIX: {
        const elementNodes = element.childNodes[0] as HTMLElement;
        src = await htmlToImage.toPng(elementNodes, {
          pixelRatio: 1,
        });
        break;
      }
      case KPI_TYPE.HEATMAP_CHART: {
        const elementNodeError = document.getElementById(
          `${chartTitle}-warning`
        );

        const elementNodes = (elementNodeError ??
          element.childNodes[0]) as HTMLElement;

        elementNodes.style.display = "block";
        src = await htmlToImage.toPng(elementNodes, {
          pixelRatio: 1,
        });
        break;
      }
      case KPI_TYPE.TABLE: {
        // Get the pagination informations because only the current page will be displayed on the document
        const nbPaginationSelect: Element | null = element.querySelector(
          '[class*="MuiTablePagination-select"]'
        );
        const paginationCaptions: NodeListOf<Element> =
          element.querySelectorAll('[class*="MuiTablePagination-caption"]');
        const lastPaginationCaption =
          paginationCaptions[paginationCaptions.length - 1];
        const nbItemsPerPages = nbPaginationSelect?.innerHTML;
        const nbInfos = lastPaginationCaption?.innerHTML;
        const infos = nbInfos ? nbInfos.split(" ") : [];
        let displayedItems = infos[0];
        const totalItems = infos.length >= 2 ? infos[2] : "";
        displayedItems = displayedItems
          ? displayedItems.replace("-", " to ")
          : "";
        additionalData = {
          nbItemsPerPages,
          displayedItems,
          totalItems,
        };
        const tables = element.getElementsByTagName("table");
        src = await htmlToImage.toPng(tables[0] as HTMLElement, {
          pixelRatio: 1,
        });
        break;
      }
      default: {
        const svgDocument = parser.parseFromString(
          element.outerHTML,
          "text/xml"
        );
        const svgs = svgDocument.getElementsByTagName("svg");
        if (svgs && svgs.length > 0) {
          try {
            src = await saveSvgAsPng.svgAsPngUri(svgs[0]);
          } catch (error) {
            // don't block the execution
          }
        }
      }
    }
    const attrWidth = element.getAttribute(
      `${DATA_PDF_PRINTABLE_ATTRIBUTE}width`
    );
    const width = attrWidth ? Number.parseFloat(attrWidth) : 100;
    if (src) {
      data.push({
        title: chartTitle,
        type,
        width,
        src,
        legendSrc,
        additionalData,
      });
    }
  }

  try {
    const blobPdf = await pdf(
      buildDashboardPdf(
        fileName,
        client,
        startDate,
        endDate,
        data,
        teamFilter,
        userFilter
      )
    );
    const result = await blobPdf.toBlob();
    saveAs(result, `${fileName}.pdf`);
  } catch (e) {
    logger.logError(e);
  }
};
