import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { getQuery } from "components/Filter/Filter.utils";
import FilterDropdownManager from "components/Filter/FilterDropdownManager";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { prepareFilters } from "containers/lists/subcategories/prepareFilters";
import { IQuery } from "containers/lists/utils";
import {
  teamsComposedSelector,
  teamsSelector,
} from "containers/teams/redux/selectors";
import { allMobileUsersComposedSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allWebUsersComposedSelector } from "containers/users/redux/selectors/webUsersSelector";
import { useTranslations } from "hooks";

interface IOptionsFilterContainerProps {
  listId: string;
  onChange: (query: IQuery) => void;
}
const OptionsFilterContainer = ({
  listId,
  onChange,
}: IOptionsFilterContainerProps) => {
  const allLists = useSelector(allListsSelector);
  const mobileUsers = useSelector(allMobileUsersComposedSelector);
  const webUsers = useSelector(allWebUsersComposedSelector);
  const teams = useSelector(teamsSelector);
  const teamsLevels = useSelector(teamsComposedSelector);
  const client = useSelector(getSelectedClient);
  const lang = useTranslations();
  const list = allLists.find((list) => list.id === listId);
  const [query, setQuery] = useState<IQuery | undefined>(undefined);

  useEffect(() => {
    if (query !== undefined) {
      onChange(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  if (!list) return null;

  const filters = prepareFilters(
    list,
    mobileUsers,
    webUsers,
    teams,
    teamsLevels,
    query,
    client,
    lang,
    undefined,
    "list",
    {
      ignoreHierarchyFilter: true,
    }
  );

  return (
    <Box
      maxWidth={400}
      style={{
        overflowX: "auto",
      }}
    >
      <FilterDropdownManager
        filters={filters}
        onChangeFilters={(filters) => {
          const flatQuery = getQuery(filters);
          setQuery(flatQuery);
        }}
      />
    </Box>
  );
};

export default OptionsFilterContainer;
